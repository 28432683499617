import { ComposableFunctionArgs, Context } from '@vue-storefront/core';
import type { GetProductSearchParams } from '@vue-storefront/magento-api';
import logger from '~/utilities/logger';

export const searchReviewsCommand = {
  execute: async (context: Context, params?: ComposableFunctionArgs<GetProductSearchParams>) => {
    logger.debug('[Magento] search review params input:', JSON.stringify(params, null, 2));

    const { customQuery, ...input } = params;

    const { data } = await context.$magento.api.productReview(
      input as GetProductSearchParams,
      params?.customQuery ?? null,
    );

    logger.debug('[Result]:', { data });

    return data?.products?.items ?? [];
  },
};
