import { useContext } from '@nuxtjs/composition-api';
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime";

export enum ImageSize {
  Default = 'default',
  Large = 'large',
  Thumbnail = 'thumbnail',
  Original = 'original',
}

export type Source = 'bloomreach' | 'magento';

export const transformImageUrlToSize = (
  url: string,
  size: ImageSize = ImageSize.Default,
  source: Source = 'magento',
  config: NuxtRuntimeConfig = null
): string => {
  if (!url) return;

  if (process.client) {
    config = window.$nuxt.$config;
  } else if (!config) {
    config = useContext().$config;
  }

  const sizeHashes = {
    default: config.imageHashDefault,
    thumbnail: config.imageHashThumbnail,
    large: config.imageHashLarge,
    original: config.imageHashOriginal,
  };

  const sizeHashesBloomreach = {
    default: config.imageHashBloomreachDefault,
    thumbnail: config.imageHashBloomreachThumbnail,
    large: config.imageHashBloomreachLarge,
    original: config.imageHashBloomreachOriginal,
  };

  const hash = source === 'magento' ? sizeHashes[size] : sizeHashesBloomreach[size];

  if (url?.indexOf('cache/') > 0) {
    if (size === 'original') {
      return url?.replace(/cache\/([a-z0-9]+)\//, '');
    }

    return url?.replace(/cache\/([a-z0-9]+)\//, `cache/${hash}/`);
  }
  return url?.replace('catalog/product/', `catalog/product/cache/${hash}/`);
};
