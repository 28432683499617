import type { Context } from '@nuxt/types';
import logger from '~/utilities/logger';
import { usePageStore } from '~/stores';
import { storeToRefs } from 'pinia';
import type { PageModel } from '@bloomreach/spa-sdk';
import Vue from 'vue';

export type CategoryId = number | 'global';
export type Sku = string;

export default function useBloomreachPage(context: Context) {
  const { bloomreachPages } = storeToRefs(usePageStore());

  const getBloomreachPage = async (
    searchTerm: Sku | CategoryId,
    searchTermRequestParameter: string,
    pageType: 'pdp' | 'pcp' = 'pdp',
  ): Promise<PageModel> => {
    const storeKey = searchTerm + searchTermRequestParameter;

    if (!bloomreachPages.value[storeKey]) {
      //prevent requests from racing
      Vue.set(bloomreachPages.value, storeKey, {});
      const bloomreachPage: PageModel = await context.$axios
        .get(
          `${context.$brxmEndpointResolver.resolvePagesEndPoint()}/${pageType}?${searchTermRequestParameter}=${searchTerm}`,
        )
        .then((res) => res.data)
        .catch((error) => logger.warn(`Failed to get bloomreachPage: ${searchTerm}`, error));

      Vue.set(bloomreachPages.value, storeKey, bloomreachPage);
    }
    return bloomreachPages.value[storeKey];
  };

  return {
    getBloomreachPage,
  };
}
