import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import { useCartStore } from '~/stores';
import logger from '~/utilities/logger';

export const applyCouponCommand = {
  execute: async (context: Context, { currentCart, couponCode }) => {
    logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    const { data, errors } = await context.$magento.api.applyCouponToCart({
      cart_id: currentCart.id,
      coupon_code: couponCode,
    });

    const cartStore = useCartStore();
    if (data.applyCouponToCart) cartStore.setCart(data.applyCouponToCart.cart);

    logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: data.applyCouponToCart?.cart as Cart,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
};
