export default `
  query customAttributeMetadata {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "colors", entity_type: "catalog_product" }
        { attribute_code: "fit", entity_type: "catalog_product" }
        { attribute_code: "style_profile", entity_type: "catalog_product" }
        { attribute_code: "special_note", entity_type: "catalog_product" }
        { attribute_code: "product_label", entity_type: "catalog_product" }
      ]
    ) {
      items {
        attribute_code
        attribute_options {
          value
          label
        }
      }
    }
  }
`;
