import { useContext, ref } from '@nuxtjs/composition-api';
import axios from 'axios';

export const useGiftPackageProducts = () => {
  const { app, $axios } = useContext();
  const loading = ref<boolean | null>(null);
  const products = ref<[] | null>(null);

  const fetchProducts = () => {
    return axios
      .get(`/api/giftWrapProducts?locale=${app.i18n.locale}`)
      .then((res) => res.data)
      .catch((error) => {
        return {
          success: false,
          data: [],
        };
      });
  };

  const load = async () => {
    loading.value = true;
    products.value = await fetchProducts();
    loading.value = false;
  };

  return {
    load,
    loading,
    products,
  };
};

export default useGiftPackageProducts;
