import logger from '~/utilities/logger';
import { BillingCartAddress, SetBillingAddressOnCartInput } from '@vue-storefront/magento-api';

export declare type Maybe<T> = T | null;

export const saveBillingAddressCommand = {
  execute: async (context, cartId, billingDetails): Promise<Maybe<BillingCartAddress>> => {
    const { apartment, neighborhood, extra, sameAsShipping, customerAddressId, ...address } = billingDetails;

    const billingData = customerAddressId
      ? { customer_address_id: customerAddressId }
      : {
          address: {
            ...address,
            street: [address.street, apartment, neighborhood, extra].filter(Boolean),
          },
          same_as_shipping: sameAsShipping,
        };

    const setBillingAddressOnCartInput: SetBillingAddressOnCartInput = {
      cart_id: cartId,
      billing_address: billingData,
    };

    const { data } = await context.$vsf.$magento.api.setBillingAddressOnCart(setBillingAddressOnCartInput);

    logger.debug('[Result]:', { data });

    return data?.setBillingAddressOnCart?.cart?.billing_address ?? null;
  },
};
