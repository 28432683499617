export function encodeBase64(value: string): string {
  if (!value) {
    return undefined;
  } else if (process.client) {
    return window.btoa(value);
  } else {
    return Buffer.from(value, 'ascii').toString('base64');
  }
}

export function decodeBase64(value: string): string {
  if (!value) {
    return undefined;
  } else if (process.client) {
    return window.atob(value);
  } else {
    return Buffer.from(value, 'base64').toString();
  }
}

export default ({ app }, inject) => {
  inject('encodeBase64', encodeBase64);
  inject('decodeBase64', decodeBase64);
};
