export default `
  query paazlConfiguration($cartId: String = "...") {
    paazlConfiguration(cart_id: $cartId) {
      token
      consigneeCountryCode
      consigneePostalCode
      shipmentParameters {
        totalWeight
        totalPrice
        numberOfGoods
        goods {
          quantity
          weight
          price
        }
      }
    }
  }
`;
