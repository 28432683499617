export default `
  query getAdyenPaymentMethods($cartId: String!, $shopperLocale: String) {
    adyenPaymentMethods(cart_id: $cartId, shopper_locale: $shopperLocale) {
        paymentMethodsExtraDetails {
            type
            icon {
                url
                width
                height
            }
            isOpenInvoice
            configuration {
                amount {
                    value
                    currency
                }
                currency
            }
        }
        paymentMethodsResponse {
            paymentMethods {
                name
                type
                brand
                brands
                issuers {
                  id
                  name
                }
                configuration {
                    merchantId
                    merchantName
                }
                details {
                    key
                    type
                    items {
                        id
                        name
                    }
                    optional
                }
            }
        }
    }
  }
`;
