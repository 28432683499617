import { useContext } from '@nuxtjs/composition-api';
import { GetProductSearchParams } from '@vue-storefront/magento-api';

type UseContextReturn = ReturnType<typeof useContext>;

export const getProductListCommand = {
  execute: async (
    context: UseContextReturn,
    searchParams: GetProductSearchParams,
    customQuery = { products: 'products' },
  ) => {
    const { data } = await context.app.$vsf.$magento.api.products(searchParams, customQuery);

    return data?.products ?? null;
  },
};
