import getCategoryHreflangs from '~/customQueries/queries/getCategoryHreflangs.gql';

export type HrefCategory = {
  hreflang_data: {
    rel: string;
    language: string;
    url: string;
  }[];
};

export const getCategoriesCommand = {
  execute: async (context, categoryId): Promise<HrefCategory[]> => {
    const filters = {
      ids: {
        eq: categoryId,
      },
    };

    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: getCategoryHreflangs,
      queryVariables: { filters },
    });

    return data?.categories?.items || [];
  },
};
