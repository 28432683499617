export default `
  query categories($filters: CategoryFilterInput!) {
    categories(filters: $filters) {
      items {
        hreflang_data {
          rel
          language
          url
        }
      }
    }
  }
`;
