import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import useCart from '~/composables/useCart';
import { useCartStore } from '~/stores';
import { setShippingMethodsOnCartCommand } from './commands/setShippingMethodsOnCartCommand';
import type { SetShippingMethodsOnCartInput } from '@vue-storefront/magento-api';
import type { UseShippingProviderErrors, UseShippingProviderInterface } from './useShippingProvider';
import usePaazlApi from '~/composables/usePaazlApi';
import { storeToRefs } from 'pinia';
import getPaazlConfigurationGql from '~/customQueries/queries/getPaazlConfiguration.gql';

interface paazlShippingMethodParams {
  token: string;
  shippingOption: {
    identifier: string;
  };
  pickupLocation?: {
    code: string;
  };
}

/**
 * Allows loading the shipping provider
 * for the current cart and saving (selecting) other shipping provider for the
 * same cart.
 *
 * See the {@link UseShippingProviderInterface} for a list of methods and values available in this composable.
 */
export function useShippingProvider(): UseShippingProviderInterface {
  const loading = ref(false);
  const error = ref<UseShippingProviderErrors>({
    load: null,
    save: null,
  });
  const { cart, setCart, load: loadCart } = useCart();
  const cartStore = useCartStore();
  const context = useContext();
  const { app } = useContext();
  const { post } = usePaazlApi(context);

  const save = async ({ shippingMethod }) => {
    logger.debug('useShippingProvider.save');
    let result = null;
    try {
      loading.value = true;

      // Save to Paazl
      if (shippingMethod.method.method_code === 'paazlshipping') {
        const paazlShippingMethodParams: paazlShippingMethodParams = {
          token: shippingMethod.token,
          shippingOption: {
            identifier: shippingMethod.method.identifier,
          },
        };

        if (shippingMethod.pickupLocation) {
          paazlShippingMethodParams.pickupLocation = shippingMethod.pickupLocation;
        }

        await post('/checkout', paazlShippingMethodParams);
      }

      // Save to Magento
      const shippingMethodParams: SetShippingMethodsOnCartInput = {
        cart_id: cart.value.id,
        shipping_methods: [
          {
            carrier_code: shippingMethod.method.carrier_code,
            method_code: shippingMethod.method.method_code,
          },
        ],
      };

      const cartData = await setShippingMethodsOnCartCommand.execute(context, shippingMethodParams, null, null);
      logger.debug('[Result]:', { cartData });
      setCart(cartData);
      cartStore.setCart(cartData);
      result = cartData?.shipping_addresses?.[0]?.selected_shipping_method;

      // Get Paazl checkout
      //const checkout = await get(`/checkout?reference=${cartStore.shipping.token}`, true);
      // cartStore.shipping.selected_shipping_method = checkout.shippingOption;

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      logger.error('useShippingProvider/save', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const load = async () => {
    logger.debug('useShippingProvider.load');
    let result = null;
    try {
      loading.value = true;
      const cartStore = useCartStore();
      const { availableShippingMethods } = storeToRefs(cartStore);

      if (!cart.value.id) {
        await loadCart({ completeCart: true });
      }

      cartStore.shipping.shipping_options = [];
      availableShippingMethods.value.forEach((method) => {
        if (method.carrier_code === 'paazlshipping') {
          loadPaazlShipping();
        } else {
          setShippingMethods(method);
        }
      });

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      logger.error('useShippingProvider/load', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const setShippingMethods = (method) => {
    cartStore.shipping.shipping_options.push(method);
  };

  const loadPaazlShipping = async () => {
    // Get Paazl Configuration data from Magento
    const paazl = await app.$vsf.$magento.api.customQuery({
      query: getPaazlConfigurationGql,
      queryVariables: {
        cartId: cart.value.id,
      },
    });

    // Set Paazl token
    cartStore.shipping.token = paazl?.data?.paazlConfiguration?.token;

    const shippingOptionsBody = {
      consigneeCountryCode: paazl?.data?.paazlConfiguration?.consigneeCountryCode,
      consigneePostalCode: paazl?.data?.paazlConfiguration?.consigneePostalCode,
      token: cartStore.shipping.token,
      shipmentParameters: paazl?.data?.paazlConfiguration?.shipmentParameters,
      // @todo: add more shipping parameters (get them by extending the custom query)
      // I think this will give better options?
    };

    const paazlShippingOptions = await post('/shippingoptions', shippingOptionsBody, false);

    const shippingOptions = paazlShippingOptions.shippingOptions.map((option) => {
      return {
        ...option,
        method_title: option.name,
        method_code: 'paazlshipping',
        carrier_code: 'paazlshipping',
        carrier_title: option.carrier?.description,
        amount: {
          value: option.rate,
        },
      };
    });

    cartStore.shipping.shipping_options = shippingOptions;

    const pickupLocations = await post('/pickuplocations', shippingOptionsBody, false);
    cartStore.shipping.pickup_locations = pickupLocations?.pickupLocations;
  };

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useShippingProvider';
export default useShippingProvider;
