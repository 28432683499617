import { NuxtAppOptions } from '@nuxt/types';

declare module '@nuxt/types' {
  interface Context {
    // nuxtContext.$brxmEndpointResolver
    $brxmEndpointResolver: {
      resolvePagesEndPoint(): string;
      resolveDocumentsEndPoint(): string;
    };
    // nuxtContext.$recaptcha
    $recaptcha: {
      init(): void;
      reset(): void;
      getResponse(): string;
    };
  }
}

function resolveEndPoint(app: NuxtAppOptions): string {
  if (app.$config.brxmEndpoints[app.i18n.locale]) {
    return app.$config.brxmEndpoints[app.i18n.locale];
  }
  return app.$config.brxmEndpoints[app.i18n.defaultLocale];
}

export default (app: NuxtAppOptions, inject) => {
  const resolver = {
    resolvePagesEndPoint: function () {
      return resolveEndPoint(app) + '/pages';
    },
    resolveDocumentsEndPoint: function () {
      return resolveEndPoint(app) + '/documents';
    },
  };
  inject('brxmEndpointResolver', resolver);
};
