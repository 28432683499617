import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/nl';

const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
  }

  // eslint-disable-next-line no-param-reassign
  return new Intl.NumberFormat(locale, { style: 'currency', currencyDisplay: 'narrowSymbol', ...options }).format(
    value,
  );
};

export default formatCurrency;
