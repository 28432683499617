import { useContext } from '@nuxtjs/composition-api';

export const formatUrl = (url: string) => {
  const { localePath, app } = useContext();

  if (typeof url !== 'string') {
    return localePath('');
  }

  if (url.indexOf(app.$config.storeUrl) === -1) {
    const locales = app.$config.locales.map((locale) => locale.code);
    const localeRegex = new RegExp(`^\/(${locales.join('|')})\/`);

    if (localeRegex.test(url) || url.startsWith('https://') || url.startsWith('www.')) {
      return url;
    }

    return localePath(url);
  }

  if (!url.startsWith('https://') && !url.startsWith('www.')) {
    return localePath(url.replace(app.$config.storeUrl, ''));
  }
  return url;
};

export default formatUrl;
