import type { AvailablePaymentMethod } from '@vue-storefront/magento-api';
import type { PaymentMethodParamsWithProvider } from '../usePaymentProvider';
import setPaymentMethodOnCartAndPlaceOrder from '~/customQueries/queries/setPaymentMethodOnCartAndPlaceOrder';

export const setPaymentMethodOnCartCommand = {
  execute: async (context, params: PaymentMethodParamsWithProvider): Promise<AvailablePaymentMethod[]> => {
    // If Adyen payment method: add the method to cart and directly place the order
    if (params.provider === 'adyen') {
      const magentoCode = `${params.code.split('_').shift()}_additional_data_${params.code.split('_').pop()}`;
      const { data: adyen, errors } = await context.app.$vsf.$magento.api.customQuery({
        query: setPaymentMethodOnCartAndPlaceOrder(magentoCode, params.state),
        queryVariables: {
          cartId: params.cart_id,
          stateData: JSON.stringify(params.state),
          code: params.code,
        },
      });

      if (errors?.length) {
        throw errors[0].message;
      }

      return adyen.placeOrder?.order;

      //return adyen?.setPaymentMethodOnCart?.cart.selected_payment_method ?? [];
    }

    const { data } = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(
      params,
      params?.customQuery ?? null,
      params?.customHeaders,
    );

    return data?.setPaymentMethodOnCart?.cart.available_payment_methods ?? [];
  },
};
