import { parse } from 'node-html-parser';
import { useContext } from '@nuxtjs/composition-api';

export const getLocalePathFromAbsoluteUrl = (absoluteUrl: string) => {
  const { localePath, app } = useContext();

  const url = new URL(absoluteUrl);

  return localePath(url?.pathname.replace(`/${app.i18n.locale}`, '') || '');
};

export const parseBloomreachInternalLinks = (html: string) => {
  const { localePath } = useContext();
  const root = parse(html);

  root.querySelectorAll('a[data-type="internal"]').forEach((a) => {
    const href = a.getAttribute('href') || '';
    // Only perform localePath on relative urls
    if (href.indexOf('/') === 0) a.setAttribute('href', localePath(href));
  });

  return root.toString();
};
