export default (code, state) => {
  let additionalData = `{
    stateData: $stateData
  }`;

  if (code === 'adyen_additional_data_hpp') {
    additionalData = `{
        ${state.paymentMethod.type ? 'brand_code:' + state.paymentMethod.type : null}
        stateData: $stateData
        returnUrl: "${process.env.NUXT_ENV_STORE_URL}/checkout/thank-you?merchantReference=:merchantReference"
      }
    `;
  }

  return `
  mutation setPaymentMethodAndPlaceOrder( 
    $cartId: String!
    $stateData: String!
    $code: String!
  ) {
    setPaymentMethodOnCart( 
        input: {
            cart_id: $cartId
            payment_method: {
              code: $code
              ${code}: ${additionalData}
            }
        }
    ) {
        cart {
            selected_payment_method {
                code
                title
            }
        }
    }
    placeOrder( 
        input: {
            cart_id: $cartId
        }
    ) {
        order {
            order_number
            cart_id
            adyen_payment_status {
                isFinal
                resultCode
                additionalData
                action
            }
        }
    }
 }
`;
};
