import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import type { UseUserOrderErrors, UseUserOrderInterface, UseUserOrderSearchParams } from './useUserOrder';
import getAdyenPaymentStatus from '~/customQueries/queries/getAdyenPaymentStatus';

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      logger.debug('[Magento] search user orders', { params });

      const { data } = await app.$vsf.$magento.api.customerOrders({ filter: params.filter });

      const order = data?.customer?.orders.items[0];
      const adyenPaymentMethodUsed = order.payment_methods.some((method) => method.type.includes('adyen'));

      if (adyenPaymentMethodUsed) {
        const apiState = app.$vsf.$magento.config.state;
        const cartId = apiState.getCartId()?.indexOf('|')
          ? apiState.getCartId().split('|').pop()
          : apiState.getCartId();

        const { data: adyenData } = await app.$vsf.$magento.api.customQuery({
          query: getAdyenPaymentStatus,
          queryVariables: {
            orderNumber: params.filter.number.eq,
            cartId,
          },
        });

        if (adyenData.adyenPaymentStatus?.isFinal === false) {
          // Handle with webcomponent
          const { action } = adyenData.adyenPaymentStatus;
          return {
            items: [
              {
                number: order.number,
                isFinal: false,
                action: action ? JSON.parse(action) : null,
              },
            ],
          };
        }
      }

      logger.debug('[Result graphQL]:', { data });

      results = data?.customer?.orders ?? data?.customerOrders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      logger.error('useUserOrder/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';
