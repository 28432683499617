import { ComposableFunctionArgs, Context } from '@vue-storefront/core';
import logger from '~/utilities/logger';

export const loadReviewMetadataCommand = {
  execute: async (context: Context, params?: ComposableFunctionArgs<{}>) => {
    logger.debug('[Magento] load review metadata');

    const { data } = await context.$magento.api.productReviewRatingsMetadata(params?.customQuery ?? null);

    logger.debug('[Result]:', { data });

    return data?.productReviewRatingsMetadata?.items ?? [];
  },
};
