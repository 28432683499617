/**
 * Adds the filters to the URL. I.e: /bestsellers/blau-gold.html?sizes=xl
 * Retains existing query strings (like q, p, etc.)
 * @returns void
 */
import { doSearchOperations, facetIds, nonPrettyUrlFacets } from '../index';
import { useBloomreachDiscoveryStore, usePageStore } from '~/stores';
import { storeToRefs } from 'pinia';
import logger from '~/utilities/logger';

export default async (context, moreProducts: string | null = null) => {
  const { routeData } = storeToRefs(usePageStore());
  const router = context.app.router;
  const { localePath } = context.app;
  const {
    prependedPage,
    currentPage,
    selectedFilters,
    selectedSortOption,
    appliedFilters,
    selectedPrettyUrls,
    prettyUrls,
    facets,
  } = storeToRefs(useBloomreachDiscoveryStore());

  const page = moreProducts === doSearchOperations.PREPEND ? prependedPage.value : currentPage.value;

  const filters: Record<string, string> = JSON.parse(JSON.stringify(selectedFilters.value)); // Remove reactivity

  selectedPrettyUrls.value = [];
  const queryStringFilters = {};

  const prettyUrlFiltersToStrings = [];
  const handlePrettyUrl = (key: string, value: string) => {
    selectedPrettyUrls.value.push(value);
    const prettyUrl = prettyUrls.value?.find((prettyUrl) => prettyUrl.label === value.replaceAll('-', ''));
    const isFacet = !!facets.value?.find((facet) => facet.id === prettyUrl?.type);

    if (!isFacet) {
      logger.warn(`Found pretty URL "${value}", but it doesn't have a corresponding facet type`);
    }

    if (prettyUrl?.slug && isFacet) {
      prettyUrlFiltersToStrings.push(prettyUrl.slug);
    } else {
      queryStringFilters[key]
        ? (queryStringFilters[key] = [...queryStringFilters[key], value])
        : (queryStringFilters[key] = [value]);
    }
  };

  // Split filters into pretty url filters and query string filters
  for (const [key, value] of Object.entries(filters)) {
    if (!value || (Array.isArray(value) && value.length < 1)) continue;
    if (nonPrettyUrlFacets.includes(key) || routeData.value?.type !== 'CATEGORY') {
      queryStringFilters[key] = Array.isArray(value) ? value : [value];
    } else {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          handlePrettyUrl(key, val);
        });
      } else {
        handlePrettyUrl(key, value);
      }
    }
  }

  let relativeUrl = routeData.value?.relative_url;
  // check if url already contains pretty filters
  if (
    prettyUrlFiltersToStrings.some(
      (filter) =>
        relativeUrl.includes(`/${filter}.html`) ||
        relativeUrl.includes(`-${filter}`) ||
        relativeUrl.includes(`${filter}-`),
    )
  ) {
    const urlParts = relativeUrl.split('/');
    // remove pretty filters part
    urlParts.pop();
    relativeUrl = `${urlParts.join('/')}.html`;
  }

  const urlString = prettyUrlFiltersToStrings.length
    ? localePath(`/${relativeUrl.replace('.html', `/${prettyUrlFiltersToStrings.join('-')}.html`)}`)
    : localePath(`/${relativeUrl}`);

  const url = new URL(context.app.$config.storeUrl + urlString);

  // Retain existing query strings, except for the facet filters
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.forEach((value, key) => {
    if (facetIds.includes(key)) return url.searchParams.delete(key);
    url.searchParams.append(key, value);
  });

  // Add the facet filters to the query string
  Object.keys(queryStringFilters).forEach((key) => {
    if (!queryStringFilters[key]?.length) return;
    url.searchParams.append(key, queryStringFilters[key]);
  });

  // Add sort option to query string
  url.searchParams.delete('sort');
  if (selectedSortOption.value) {
    url.searchParams.append('sort', selectedSortOption.value);
  }

  // Pagination
  if (JSON.stringify(appliedFilters.value) !== JSON.stringify(selectedFilters.value)) {
    currentPage.value = 1;
  }

  url.searchParams.delete('p');
  if (page > 1) {
    url.searchParams.append('p', page.toString());
  }

  const navigationDetails = {
    path: url.pathname,
    query: Object.fromEntries(url.searchParams.entries()),
    force: true,
  };

  await router.push(navigationDetails);
};
