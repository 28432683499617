import { CustomQuery } from '@vue-storefront/core';
import type { AvailablePaymentMethod } from '@vue-storefront/magento-api';
import { useCartStore } from '~/stores';
import getAdyenPaymentMethods from '~/customQueries/queries/getAdyenPaymentMethods';

export const getAvailablePaymentMethodsCommand = {
  execute: async (
    context,
    cartId: string,
    customQuery?: CustomQuery,
    customHeaders?,
  ): Promise<AvailablePaymentMethod[]> => {
    const cartStore = useCartStore();

    // Get Magento payment methods
    const { data } = await context.app.$vsf.$magento.api.getAvailablePaymentMethods(
      { cartId },
      customQuery,
      customHeaders,
    );

    const availablePaymentMethods = data?.cart?.available_payment_methods;

    // If Adyen is mentioned in the Magento paymentmethods, get Adyen paymentmethods
    if (availablePaymentMethods?.find((method) => method.code.includes('adyen'))) {
      const { data: adyen } = await context.app.$vsf.$magento.api.customQuery({
        query: getAdyenPaymentMethods,
        queryVariables: {
          cartId,
        },
      });

      cartStore.payment.adyen_payment_methods = adyen?.adyenPaymentMethods ?? [];

      // Remove Adyen from Magento payment methods, since those are being handled by Adyen now
      cartStore.payment.available_payment_methods =
        availablePaymentMethods?.filter((method) => !method.code.includes('adyen')) || [];
    }

    return availablePaymentMethods ?? [];
  },
};
