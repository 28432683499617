import _debounce from '~/helpers/debounce';
import { useContext } from '@nuxtjs/composition-api';

interface CacheItemData {
  customer_ids: string;
  attributes: Array<any>;
}

interface CacheItem {
  url: string;
  data: CacheItemData;
  type: string;
  attributesLength: number;
}

let cache: CacheItem[] = [];
let cacheLoading = true;
let cacheResult;

const debounceAndConsolidate = _debounce(async (context) => {
  // Condsolidate attributes
  let attributes = [];
  for (let item of cache) {
    attributes = [...attributes, ...item.data?.attributes];
  }

  const consolidated = cache[0];

  // Check if object exists
  if (!consolidated) {
    cache = [];
    return;
  }

  consolidated.data.attributes = attributes;

  // Make the call
  cacheResult = await useExponeaApi(context).postConsolidated(consolidated.url, consolidated.data, consolidated.type);
  cacheLoading = false;

  // Reset caching variables
  cache = [];
}, 500);

const cacheHandler = async (url, data, type, context) => {
  // Push call to cache
  cacheLoading = true;
  const cachePositionStart = cache.length
    ? cache.map((item) => item.attributesLength).reduce((curr, prev) => curr + prev)
    : 0;
  const cachePositionEnd = cachePositionStart + data.attributes.length;
  cache.push({ url, data, type, attributesLength: data.attributes.length });

  // Consolidate all requested items and make the call
  debounceAndConsolidate(context);

  // Wait until call is made
  while (cacheLoading) {
    await new Promise((resolve) => setImmediate(resolve));
  }

  // Get the requested data back and return it
  return cacheResult.slice(cachePositionStart, cachePositionEnd);
};

export const useExponeaApi = (context = null) => {
  const { $axios, $exponeaAuthenticationResolver } = context || useContext();
  const { projectToken, authKey } = $exponeaAuthenticationResolver.resolve();
  const baseUrl = (type: string) => `/api/exponea/${type}/v2/projects/${projectToken}`;

  const get = async (url: string, type = 'data') => {
    const response = await $axios.get(baseUrl(type) + url, { headers: authHeader() });
    return handleResponse(response);
  };

  const post = async (url: string, data, type = 'data') => {
    if (url === '/customers/attributes' && 'cookie' in data.customer_ids) {
      // This url tends to be called more often at once. So consolidate it.
      return await cacheHandler(url, data, type, context);
    } else {
      return postConsolidated(url, data, type);
    }
  };

  const postConsolidated = async (url: string, data, type = 'data') => {
    try {
      const response = await $axios.post(baseUrl(type) + url, data, { headers: authHeader() });
      return handleResponse(response);
    } catch (error) {
      return handleResponse(error.response);
    }
  };

  const authHeader = (): any => {
    return {
      'content-type': 'application/json',
      Authorization: `Basic ${authKey}`,
    };
  };

  const handleResponse = (response) => {
    const data = response.data;
    if (response.status === 404) {
      throw [response.statusText];
    }
    if (response.status !== 200 || data.success !== true) {
      throw data?.errors || [response.statusText];
    }
    return data.results || data;
  };

  return {
    get,
    post,
    postConsolidated,
  };
};

export default useExponeaApi;
