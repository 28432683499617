import Vue from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';

Vue.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    iframe: {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['src', 'allow', 'scrolling', 'style'],
    },
  },
});
