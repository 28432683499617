import { reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { useBloomreachPage, useCache } from '~/composables';
import logger from '~/utilities/logger';
import type { Usp } from '~/stores/pcpUspsStore/pcpUspsStore';
import { CategoryId } from '~/composables/useBloomreachPage';
import { PageModel } from '@bloomreach/spa-sdk';

export const pcpUspsStore = defineStore('pcpUsps', () => {
  const { $brxmEndpointResolver, $axios, app } = useContext();
  const locale = app?.i18n?.locale || 'de';
  const { get: getCache, set: setCache } = useCache('PCP');
  const { getBloomreachPage } = useBloomreachPage(useContext() as any);

  const state = reactive({
    usps: <Record<string, Usp[]>>{},
    currentUsps: <Usp[]>[],
    loading: <boolean>false,
  });

  const mapDataToUsps = (data: any): Usp[] => {
    const messages = Object.keys(data)
      .filter((key) => key.includes('message'))
      .sort()
      .map((key) => data[key]);

    const icons = Object.keys(data)
      .filter((key) => key.includes('icon'))
      .sort()
      .map((key) => data[key]);

    const mappedUsps: Usp[] = messages.map((message, i) => ({
      message,
      icon: icons[i] ?? '',
    }));

    return mappedUsps;
  };

  const getAndSetUSPfromCache = (searchTerm: CategoryId | string): Usp[] => {
    if (state.usps[searchTerm]) return state.usps[searchTerm];
    const cacheUsps = getCache(`pcpUsps_${locale}_${searchTerm}`);
    if (cacheUsps) {
      state.usps[searchTerm] = cacheUsps;
      return cacheUsps;
    }
  };

  const getUSP = async (searchTerm: CategoryId): Promise<Usp[]> => {
    if (getAndSetUSPfromCache(searchTerm)) return;
    const pageData: PageModel = await getBloomreachPage(searchTerm, 'u', 'pcp');
    if (!pageData?.page) return;

    const data =
      Object.values(pageData.page).find((object) => object?.data?.contentType === 'brxsaas:mjPLPUSP')?.data || {};

    const usp = mapDataToUsps(data);
    setCache(`pcpUsps_${locale}_${searchTerm}`, usp);
    return usp;
  };

  const getGlobalUsps = async () => {
    try {
      if (getAndSetUSPfromCache('global')) return;
      const { data } = await $axios.get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/pcpusp/global`);
      const usp = mapDataToUsps(data?.content[data?.document?.$ref.substring(9)].data);
      setCache(`pcpUsps_${locale}_global`, usp);
      state.usps['global'] = usp;
    } catch (error) {
      logger.warn('Failed to global usps', error);
    }
  };

  const loadPcpUsps = async (categoryIds: CategoryId[] = []) => {
    if (state.loading) return;

    state.loading = true;

    await getGlobalUsps();

    await Promise.all(
      categoryIds.map(async (categoryId) => {
        if (!state.usps?.[categoryId]) {
          const categoryUsps = await getUSP(categoryId);
          if (!categoryUsps) return;
          state.usps = {
            ...state.usps,
            [categoryId]: categoryUsps,
          };
        }
      }),
    );

    // Get first available USPs, fallback on global
    categoryIds.push('global');
    state.currentUsps = categoryIds.reduce((acc, categoryId) => {
      if (!acc.length && state.usps[categoryId]) {
        acc = state.usps[categoryId];
      }
      return acc;
    }, []);

    state.loading = false;
  };

  return {
    ...toRefs(state),
    loadPcpUsps,
  };
});

export default pcpUspsStore;
