import { useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';

type UseContextReturn = ReturnType<typeof useContext>;

export const loadBlocksCommand = {
  execute: async (context: UseContextReturn, params) => {
    logger.debug('[Magento]: Load CMS Blocks content', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { data } = await context.app.$vsf.$magento.api.cmsBlocks(params.identifiers, params.customQuery ?? null);

    logger.debug('[Result]:', { data });

    return data.cmsBlocks.items;
  },
};
