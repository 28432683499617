import { AvailableShippingMethod } from '@vue-storefront/magento-api';

export const getCustomerShippingMethodsCommand = {
  execute: async (context, params): Promise<AvailableShippingMethod[]> => {
    const data = await context.app.$vsf.$magento.api.getAvailableCustomerShippingMethods(
      params?.customQuery ?? null,
      params?.customHeaders,
    );
    return null; //shippingAddresses[0]?.available_shipping_methods ?? [];
  },
};
