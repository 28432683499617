import { defineStore } from 'pinia';
import { reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';

export interface TopNavState {
  ctaItems: Record<string, any>[];
  uspItems: Record<string, any>[];
  ctaUspsLoading: boolean;
  ctaUspsLoaded: boolean;
}

export const useTopNavStore = defineStore('topNav', () => {
  const { $axios, $brxmEndpointResolver } = useContext();

  const state: TopNavState = reactive({
    ctaItems: [],
    uspItems: [],
    ctaUspsLoading: false,
    ctaUspsLoaded: false,
  });

  const fetchDocumentData = async (ref: string) => {
    return await $axios
      .get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/${ref}`)
      .then((res) => res.data)
      .then((data) => {
        const headerLink =
          data?.content?.[data?.content[data?.document?.$ref.substring(9)].data?.headerLink?.$ref?.substring(9)]?.links
            ?.site?.href || null;
        return { ...data?.content[data?.document?.$ref.substring(9)].data, headerLink } || {};
      })
      .catch((error) => {
        logger.warn(`Failed to fetch document data /${ref}`, error);
        return {};
      });
  };

  const loadMobileHeaderItems = async () => {
    state.ctaUspsLoading = true;
    const items = await fetchDocumentData('header/mobileheaderctausps');
    state.ctaItems = await Promise.all(items?.header1?.map((item) => item.$ref.substring(9)).map(fetchDocumentData));
    state.uspItems = await Promise.all(items?.header2?.map((item) => item.$ref.substring(9)).map(fetchDocumentData));
    state.ctaUspsLoading = false;
    state.ctaUspsLoaded = true;
  };

  return {
    ...toRefs(state),
    loadMobileHeaderItems,
  };
});

export default useTopNavStore;
