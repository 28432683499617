import emptyPageResponse from './emptyPageResponse.json';
import logger from '~/utilities/logger';
import { usePageStore, useProductStore, useCategoryStore } from '~/stores';
import { storeToRefs } from 'pinia';
import { initialize } from '@bloomreach/spa-sdk';
import { getProductDetailsCommand } from '~/composables/useProduct/commands/getProductDetailsCommand';
import type { Page, Configuration } from '@bloomreach/spa-sdk';
import type { MJProductDetails } from '~/composables/useProduct';
import type { GetProductSearchParams } from '@vue-storefront/magento-api';
import type { Product } from '@/types/interfaces/product';

const pageTypesMap = {
  blogPost: 'BlogPost',
  customerService: 'CustomerService',
  shop: 'StoreDetail',
  twoColumn: 'TwoColumn',
};

type RouteData = {
  route?: {
    type: string;
    id?: string;
    sku?: string;
    relative_url: string;
    query: Record<string, string>;
  };
};

const useBloomreach = (context) => {
  const pageStore = usePageStore();
  const productStore = useProductStore();
  const { getCategoryHrefLangs } = useCategoryStore();
  const { pageLayout, pageType, pageData, hrefLangs, routeData } = storeToRefs(pageStore);

  const getProduct = async (sku: string) => {
    try {
      if (productStore.products[sku]) {
        return (productStore.activeProductSku = sku);
      }
      // Otherwise, get it from Magento
      productStore.setProductLoading(sku, true);

      const searchParams: GetProductSearchParams = {
        filter: {
          sku: {
            eq: sku,
          },
        },
      };
      const result: MJProductDetails = await getProductDetailsCommand.execute(context, searchParams);

      const product = result?.items?.[0] as Product;
      await productStore.setProduct(product);
      return product;
    } catch (err) {
      logger.error(`useProduct/${sku}/search`, err);
      throw context.error({ statusCode: 404 });
    } finally {
      productStore.setProductLoading(sku, false);
    }
  };

  /**
   * Checks if it's a catalog page, and if it's a product or category page
   * and returns its route data and path
   *
   * If it has a pretty url (e.g. /en/jewellery/gold), it'll fail and we'll try to get the parent
   *
   * @param pagePath
   * @returns route data | null
   */
  const handleCatalogPages = async (pagePath: string) => {
    const clearUrl = context.route.path.replace(`/${context.i18n.locale}`, '').replace(/[a-z]+\/[cp|]\//gi, '');
    const parentUrl = clearUrl.split('/').length > 2 ? `${clearUrl.split('/').slice(0, -1).join('/')}.html` : '';

    let path = pagePath;
    let page = null;

    while (!page) {
      try {
        let data: RouteData = {};
        if (context.req?.headers?.['x-vsf-id'] && context.req?.headers?.['x-vsf-pagetype']) {
          const skuOrId = context.req.headers['x-vsf-pagetype'] === 'PRODUCT' ? 'sku' : 'id';
          data = {
            route: {
              type: context.req.headers['x-vsf-pagetype'],
              [skuOrId]: context.req.headers['x-vsf-id'],
              relative_url: context.req.headers['x-vsf-relative-url'],
              query: context.route.query,
            },
          };
        } else {
          data = (await context.$vsf.$magento.api.urlResolver(path))?.data;
        }

        if (!data.route) throw new Error('No route data');

        page = data;
      } catch (err) {
        // Possibly the url is a SEO-friendly facet url, check the parentUrl
        if (!parentUrl || path === parentUrl) {
          throw context.error({ statusCode: 404 });
        }

        // If the url is a pretty url, we'll try again with the parentUrl
        path = parentUrl;
      }
    }

    if (!['PRODUCT', 'CATEGORY'].includes(page.route?.type)) {
      const message = `Page type not found for url: ${clearUrl}, Type: ${page.route?.type}`;

      logger.error(message);
      throw context.error({
        statusCode: 404,
        message: message,
      });
    }

    if (page.route?.type === 'PRODUCT') {
      if (path !== pagePath) {
        const message = 'product subpage does not exist';
        logger.error(message);
        throw context.error({
          statusCode: 404,
          message,
        });
      }
      await getProduct(page.route.sku);
    }

    if (page.route?.redirectCode) {
      return context.redirect(context.app.localePath(`/${page.route.relative_url}`), page.route.redirectCode);
    }

    const data = {
      ...page.route,
      query: context.route.query,
      path: page.route?.type === 'PRODUCT' ? '/pdp' : '/pcp' + pagePath.replace('.html', ''),
    };

    const relativeUrl = '/' + page.route.relative_url.replace('.html', '');
    const substractedUrl = clearUrl?.replace('.html', '')?.replace(relativeUrl, '');
    if (substractedUrl) {
      const facets = substractedUrl.split('/').slice(-1).join('/').split('-');
      data.facets = facets;
    }

    pageStore.routeData = { ...data } || {};

    return data;
  };

  const getBloomReachPage = async (
    locale: string,
    resolvedPath: string,
    pageType: string,
  ): Promise<{ page: Page; configuration: Configuration }> => {
    const endpoints = context.app.$config.brxmEndpoints;
    const endpoint = endpoints[locale as keyof typeof endpoints] + '/pages';

    const httpClient = context.$axios.create();
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const statusCode = error.response?.status || 500;

        /**
         * When the authentication error occurs, remove cookies and force reload.
         */
        if (statusCode === 401) {
          context.app.$cookies.remove('token');
          context.app.$cookies.remove('server-id');

          logger.error('Axios intercepted error', error.request);

          context.error({ statusCode });
          return Promise.resolve({
            data: emptyPageResponse,
          });
        }

        if (statusCode === 403) {
          logger.error(error.request);
          context.error({ statusCode });
          return Promise.resolve({
            data: emptyPageResponse,
          });
        }
      },
    );

    const PREVIEW_TOKEN_KEY = 'token';
    const PREVIEW_SERVER_ID_KEY = 'server-id';

    let configuration: Configuration = {
      debug: process.env.NUXT_APP_ENV === 'development',
      baseUrl: '',
      endpoint,
      serverId: null,
      authorizationToken: null,
      visitor: context.nuxtState?.visitor,
      path: resolvedPath,
      httpClient,
    };

    if (context) {
      // Read a token and server id from the query string
      const queryToken = context.query[PREVIEW_TOKEN_KEY];
      const queryServerId = context.query[PREVIEW_SERVER_ID_KEY];

      // Make priority to values from query string because in cookies they might be outdated.
      const authorizationToken = queryToken ?? context.app.$cookies.get(PREVIEW_TOKEN_KEY);
      const serverId = queryServerId ?? context.app.$cookies.get(PREVIEW_SERVER_ID_KEY);

      // Save the values from the query string to have ability to restore them when switch back from legacy page to the SPA-SDK rendered page.
      if (queryToken) context.app.$cookies.set(PREVIEW_TOKEN_KEY, queryToken);
      if (queryServerId) context.app.$cookies.set(PREVIEW_SERVER_ID_KEY, queryServerId);

      configuration.serverId = serverId;
      configuration.authorizationToken = authorizationToken;
    }

    let path = resolvedPath;
    let page: Page | null = null;

    // Try to get the page from Bloomreach, keep trying until we get a page or we're at the root
    while (!page) {
      try {
        page = (await initialize(configuration)) as Page;
      } catch (err) {
        if (pageType !== 'BLOOMREACH') {
          // Not all categories and products are indexed in Bloomreach, so we need to get a fallback page
          path = path.substring(0, path.lastIndexOf('/'));
          if (!path) break;

          configuration.path = path;
        } else {
          logger.error(
            '[useBloomreach] getBloomReachPage',
            JSON.stringify(
              {
                path: path,
                statusMessage: 'Bloomreach page not found!',
              },
              null,
              2,
            ),
          );
          context.error({ statusCode: 404 });
          break;
        }
      }
    }

    return { page, configuration };
  };

  const getHrefLangs = async (page: Page) => {
    const pageData = (page?.getDocument() as any)?.getData();
    let hreflangs = [];

    if (pageData?.hreflangItems?.length) {
      hreflangs =
        pageData?.hreflangItems?.map((item) => {
          const alt = page?.getContent(item)?.getData();
          const locale =
            alt.hreflang?.substring(0, 2).toLowerCase() === 'nl'
              ? 'nl-nl'
              : alt.hreflang?.substring(0, 2).toLowerCase();
          let href;

          try {
            href = new URL(alt.href).pathname;
          } catch {
            href = alt.href.replace(context.app.$config.storeUrl, '');
          }

          return {
            hid: `alternate-${alt.name}`,
            rel: 'alternate',
            hreflang: alt.hreflang,
            href: context.app.localePath(href, locale),
            locale,
            iso: alt.hreflang?.substring(0, 2).toLowerCase(),
          };
        }) ?? [];
    } else if (routeData.value?.id) {
      hreflangs = await getCategoryHrefLangs(context, routeData.value?.id);
    } else if (routeData.value?.sku) {
      hreflangs = productStore.getProductHreflangs(routeData.value?.sku);
    }

    return hreflangs;
  };

  const cacheInvalidate = () => {
    // Cache invalidate SSR only so we don't expose the invalidation url to the public
    try {
      if (context.route.query['token'] && context.$config.redisCache.enabled) {
        context.$axios({
          url: `${context.$config.redisCache.invalidateUrl}?key=${
            context.$config.redisCache.invalidateKey
          }&tags=CMS${context.route.path.replace(/\/+$/, '')}&timestamp=${Date.now()}`,
          baseURL: context.$config.ssrMiddlewareUrl.replace('/api', ''),
        });
      }
    } catch (error) {
      logger.error('Unable to invalidate cache:', error, {
        baseUrl: context.$config.ssrMiddlewareUrl.replace('/api', ''),
        path: `/${context.$config.redisCache.invalidateUrl}?key=${
          context.$config.redisCache.invalidateKey
        }&tags=CMS${context.route.path.replace(/\/+$/, '')}&timestamp=${Date.now()}`,
      });
    }
  };

  const getInternalUrl = async (documentRef) => {
    const { $brxmEndpointResolver, $axios, app } = context;
    return await $axios
      .get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/${documentRef}`)
      .then((res) => res.data)
      .then((data) => {
        return app.localePath(
          data.content?.['u' + documentRef.replaceAll('-', '')]?.links?.site?.href.replace('/pcp', '') + '.html',
        );
      })
      .catch((error) => {
        logger.warn(`Failed to fetch document data /${documentRef}`, error);
        return {};
      });
  };

  const getQuickFilters = async (page: any): Promise<any[]> => {
    let pageContent = page?.model?.page;
    if (!pageContent) return [];
    const mjQuickFilter: any = Object.values(pageContent).find((item: any) => item.ctype === 'mjQuickFilter');
    const documentRef = mjQuickFilter?.models?.document?.$ref?.replace('/page/', '');
    const data = pageContent[documentRef]?.data;
    const quickFilterItems = data?.quickFilterItems;
    if (!quickFilterItems?.length) return [];

    let quickFilterList = [];

    await Promise.all(
      quickFilterItems?.map(async (item) => {
        const data = pageContent[item.$ref.replace('/page/', '')]?.data;
        if (!data) return;
        const url = data.ctaLink?.uuid ? await getInternalUrl(data.ctaLink?.uuid) : data.ctaUrl;

        quickFilterList.push({
          id: data.categoryID,
          name: data.title,
          url,
        });
      }),
    );
    return quickFilterList;
  };

  // Page path doesn't end with .html (not a catalog page)
  // • Get the page from Bloomreach
  //
  // Page path does end with .html (catalog page, so either a product or category page)
  // • Get the page from Magento to determinde if it's a product or category page
  // If it's a found:
  // • Get the page from Bloomreach
  //
  // If it's not found, it could be a pretty url (e.g. /en/jewellery/gold) so:
  // • Get the parent page from Magento
  // • Get the page from Bloomreach
  //    • If Bloomreach can't find the page, get the parent
  //    • If Bloomreach can't find the parent, get the fallback page from Bloomreach (/PCP)
  //
  // If still not found:
  // • 404

  const getPage = async (staticPath?: string) => {
    // Save previous page on navigation
    pageStore.previousPage.path = pageStore.routeData?.path;
    pageStore.previousPage.type = pageStore.routeData?.type;
    pageStore.previousPage.name = pageStore.routeData?.name;

    routeData.value = context.route.meta[0];

    // Get initial path
    const path = context.route.path;
    const locale: string = path?.toString().split('/')[1] || context.app.$config.locales[0];

    const initialPath =
      typeof staticPath === 'undefined' ? `${path?.toString().replace('/' + locale, '') || ''}` : staticPath;

    // Set default values
    let resolvedPath = initialPath;
    let resolvedPageType = 'BLOOMREACH';
    let sku = null;

    // If catalog page (PDP or PCP)
    if (initialPath.includes('.html')) {
      const catalogData = await handleCatalogPages(initialPath);
      resolvedPath = catalogData?.path;
      resolvedPageType = catalogData?.type;
      sku = catalogData?.sku;
    } else {
      // We want to show the category_breadcrumbs if navigating from PCP to PDP ,otherwise we don't need them
      pageStore.previousPage.category_breadcrumbs = [];
    }

    // Get Bloomreach page
    const { page, configuration } = await getBloomReachPage(locale, resolvedPath, resolvedPageType);

    pageStore.quickFilters = await getQuickFilters(page);

    if (!pageStore.routeData.name) {
      // @ts-ignore
      pageStore.routeData.name = page?.getDocument()?.getData()?.displayName;
    }
    pageData.value = (page as any)?.getDocument()?.getData() || {};
    const pageDocumentType = (pageData.value?.contentType as any)?.split('brxsaas:').pop();
    pageType.value = resolvedPageType;
    pageLayout.value = pageTypesMap[pageDocumentType] || page?.getComponent()?.getName() || 'one-column';
    hrefLangs.value = await getHrefLangs(page);

    cacheInvalidate();

    logger.info(
      '[useBloomreach][getPage]',
      JSON.stringify(
        {
          path,
          magentoPath: resolvedPath,
          bloomreachPath: configuration.path,
        },
        null,
        2,
      ),
    );

    return {
      page: page,
      pageData: pageData.value,
      pageType: resolvedPageType,
      pageLayout: pageLayout.value,
      hrefLangs: hrefLangs.value,
      productSku: sku,
      configuration,
    };
  };

  return {
    getPage,
    emptyPageResponse,
  };
};

export default useBloomreach;
