// export const range2 = (start, stop, step = 5) =>
//   Array(Math.ceil((stop - start) / step))
//     .fill(start)
//     .map((x, y) => x + y * step);

export const range = (start, stop, step) => {
  var a = [start];
  while (start < stop) {
    start += step || 1;
    a.push(start);
  }
  return a;
};
