import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import { RemoveItemFromCartInput } from '@vue-storefront/magento-api';
import { useCartStore } from '~/stores';
import logger from '~/utilities/logger';

export const removeItemCommand = {
  execute: async (context: Context, { currentCart, product }) => {
    logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };

    const { data, errors } = await context.$magento.api.removeItemFromCart(removeItemParams);

    const cartStore = useCartStore();
    cartStore.setCart(data.removeItemFromCart.cart);

    logger.debug('[Result]:', { data, errors });

    if (!data?.removeItemFromCart && errors?.length) {
      throw errors[0];
    }

    return data.removeItemFromCart.cart as Cart;
  },
};
