import { Context } from '@nuxt/types';
import { AvailableShippingMethod } from '@vue-storefront/magento-api';

export const getGuestShippingMethodsCommand = {
  execute: async (context: Context['app'], params): Promise<AvailableShippingMethod[]> => {
    const { data } = await context.app.$vsf.$magento.api.getAvailableShippingMethods(
      { cart_id: params.cartId },
      params?.customQuery ?? null,
      params?.customHeaders,
    );
    const hasAddresses = data.cart.shipping_addresses.length > 0;

    return hasAddresses ? data?.cart?.shipping_addresses[0]?.available_shipping_methods : [];
  },
};
