import { defineStore } from 'pinia';
import type { Product } from '@/types/interfaces/product';
import type { Attribute, AttributeOption } from './useProductStore';

import { reactive, toRefs } from '@nuxtjs/composition-api';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';
import YoutubeVimeoUrlParser from '~/helpers/YoutubeVimeoUrlParser';
import { getAttributeData } from './helpers/getAttributeData';
import logger from '~/utilities/logger';
import Vue from 'vue';

export const useProductStore = defineStore('product', () => {
  const state = reactive({
    activeProductSku: <string | null>null,
    products: <{ [pid: number]: Product }>{},
    configurableOptions: <{ [pid: number]: any }>{},
    productLoading: <{ [pid: number]: Boolean }>{},
    productCustomOptionsConfiguration: <any>{},
    attributes: <Attribute[]>[],
    giftcardHolder: <Product | null>null,
    media: <{ [pid: number]: [] }>{},
  });
  const setProduct = async (newProduct: Product) => {
    state.activeProductSku = newProduct.sku;
    state.giftcardHolder = null;

    newProduct?.configurable_options?.sort((a, b) => a.position - b.position);

    // safari <16 doesn't support ISO 8601 with space instead of T, so we need to replace it
    if (newProduct?.date_online) {
      newProduct.date_online = newProduct.date_online.replace(/\s/, 'T');
    }

    Vue.set(state.products, newProduct.sku, newProduct);

    await setProductMedia();
    updateConfigurableOptions({});
  };

  const getProductHreflangs = (sku: string) => {
    if (state.products[sku].hreflang_data?.length) {
      return state.products[sku].hreflang_data?.map((alt) => {
        return {
          hid: `alternate-${alt.language}`,
          rel: alt.rel,
          href: alt.url,
          url: alt.url,
          hreflang: alt.language,
          language: alt.language,
        };
      });
    }
  };

  const updateGiftcardConfiguration = (optionUid: string, variantUid: string) => {
    const attribute = state.products[state.activeProductSku]?.configurable_options.find(
      (option) => option.attribute_uid === optionUid,
    );
    if (attribute?.attribute_code !== 'giftcard_price') return;

    const value = attribute.values.find((attribute) => attribute.uid === variantUid);
    const attributePrice = parseInt(value?.swatch_data?.value) || 0;
    if (!attributePrice) return;

    state.products[state.activeProductSku] = {
      ...state.products[state.activeProductSku],
      price_range: {
        ...state.products[state.activeProductSku]?.price_range,
        minimum_price: {
          regular_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
          final_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
        },
      },
    };
  };

  /**
   * Retrieves attributes data.
   * @param context useContext.
   * @returns A promise that resolves to void.
   * @throws If an error occurs while retrieving the attributes data.
   */
  const getAttributes = async (context): Promise<void> => {
    try {
      state.attributes = (await getAttributeData(context)) as Attribute[];
    } catch (err) {
      throw err;
    }
  };

  /**
   * Retrieves the label of an attribute by its ID and type.
   * @param attributeId The ID of the attribute.
   * @param type The type of the attribute.
   * @returns The label of the attribute, or an empty string if not found.
   */
  const getAttributeById = (attributeId: string, type: string): string => {
    const attribute = state.attributes
      ?.find((attribute) => attribute.attribute_code === type)
      ?.attribute_options.find((option) => option.value === attributeId);
    return attribute?.label || '';
  };

  /**
   * Retrieves the attribute options for a given attribute string.
   * @param attributeString - The attribute string to retrieve options for.
   * @returns An array of AttributeOption objects or undefined if the attribute string is not found.
   */
  const getAttribute = (attributeString: string): AttributeOption[] | undefined => {
    const productAttributes = state.products[state.activeProductSku]?.[attributeString]?.split(',');
    if (!productAttributes) return;
    return state.attributes
      ?.find((attribute) => attribute.attribute_code === attributeString)
      ?.attribute_options.filter((option) => productAttributes.includes(option.value));
  };

  const productCustomOptionsCanAddToCartHandler = (sku) => {
    if (!state.products[sku]?.options?.length) return true;
    // return state.products[sku]?.options?.every((option) => state.productCustomOptionsConfiguration?.[option.uid]);
    // @todo: set to uid when MYJE-4300 is fixed
    return state.products[sku]?.options?.every((option) => state.productCustomOptionsConfiguration?.[option.option_id]);
  };

  const setProductLoading = (sku: string, value: boolean) => {
    state.productLoading = {
      ...state.productLoading,
      [sku]: value,
    };
  };

  /**
   * Waits for the product to finish loading.
   * @returns A promise that resolves to true when the product is loaded.
   */
  const waitForProduct = async (sku: string) => {
    while (state.productLoading[sku] === true) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return true;
  };

  /**
   * Sets the product media by filtering, sorting, and mapping the media gallery.
   * If a video is present, it normalizes the video URL, retrieves the video thumbnail,
   * and determines the video provider (either Vimeo or YouTube).
   * @returns {Promise<void>} A promise that resolves when the product media is set.
   */
  const setProductMedia = async (): Promise<void> => {
    try {
      const activeProductSkuMedia = await Promise.all(
        state.products[state.activeProductSku]?.media_gallery
          .filter((x) => !x.disabled)
          .sort((a, b) => a.position - b.position)
          // @ts-ignore - The type name and video_content should exist
          .map(async ({ url, label, video_content }) => {
            const asset = {
              url,
              alt: label,
            };

            if (video_content?.video_url) {
              // @ts-ignore - video should exist
              asset.video = {
                url: new YoutubeVimeoUrlParser().getNormalizedSrc(video_content.video_url),
                title: video_content.video_title,
                provider: 'regular',
                id: new YoutubeVimeoUrlParser().parseUrl(video_content.video_url).videoId,
                thumbnail: await new YoutubeVimeoUrlParser().getVideoThumbnail(video_content.video_url),
              };

              if (video_content.video_url.includes('vimeo')) {
                // @ts-ignore
                asset.video.provider = 'vimeo';
              } else if (video_content.video_url.includes('youtube')) {
                // @ts-ignore
                asset.video.provider = 'youtube';
              }
            }
            return asset;
          }),
      );

      Vue.set(state.media, state.activeProductSku, activeProductSkuMedia);
    } catch (error) {
      logger.error('useProductStore/setProductMedia', error);
    }
  };

  /**
   * Updates the product media based on the selected product configuration.
   * It filters the variants based on the selected attributes and updates the media accordingly.
   * If no variants are found, the media remains unchanged.
   */
  const updateProductMedia = (productConfiguration: Record<string, any>) => {
    const productMedia = state.media[state.activeProductSku];
    const originalMediaLength = state.products[state.activeProductSku]?.media_gallery.filter(
      (item) => !item.disabled,
    ).length;
    const isClothingCategory = state.products[state.activeProductSku]?.categories[0]?.uid === 'MjQ=';
    let variants: any = state.products[state.activeProductSku]?.variants;

    Object.values(productConfiguration).forEach((value) => {
      if (!variants?.length || !value) return;
      variants = variants?.filter((variant) => variant.attributes?.find((attribute) => attribute.uid === value));
    });

    if (!variants?.length || !productMedia || Object.values(productConfiguration).every((value) => value === null))
      return;

    // First time we add the variant image, next time we only replace it
    const mediaArray = productMedia.length > originalMediaLength ? productMedia?.slice(1) : productMedia;
    const variantImage = {
      alt: variants?.[0]?.product.thumbnail.label,
      url: transformImageUrlToSize(variants?.[0]?.product.thumbnail.url, ImageSize.Original),
    };

    Vue.set(state.media, state.activeProductSku, isClothingCategory ? mediaArray : [variantImage, ...mediaArray]);
  };

  /**
   * Updates the configurable options based on the current product configuration and stock status.
   */
  const updateConfigurableOptions = (productConfiguration: Record<string, any>) => {
    const productRef = state.products[state.activeProductSku];

    if (!productRef?.variants?.length) return;

    const configurableOptions = productRef.configurable_options.map((option, index) => {
      let options = productRef.variants;
      for (let i = 0; i <= index - 1; i++) {
        const selectedUid = productConfiguration?.[productRef.configurable_options[i]?.attribute_uid];
        if (!selectedUid) continue;
        options = options.filter((variant) => variant.attributes.find((attribute) => attribute.uid === selectedUid));
      }

      const values = option.values.map((value) => {
        const variants = options.filter((variant) =>
          variant.attributes.find((attribute) => attribute.uid === value.uid),
        );
        return {
          ...value,
          out_of_stock: variants.every((variant) => variant.product.stock_status === 'OUT_OF_STOCK')
            ? 'OUT_OF_STOCK'
            : 'IN_STOCK',
        };
      });

      return {
        ...option,
        uid: productConfiguration?.[option.attribute_uid],
        values,
      };
    });

    Vue.set(state.configurableOptions, state.activeProductSku, configurableOptions);
  };

  return {
    ...toRefs(state),
    setProductLoading,
    waitForProduct,
    setProduct,
    getProductHreflangs,
    updateGiftcardConfiguration,
    updateConfigurableOptions,
    updateProductMedia,
    getAttributes,
    getAttribute,
    getAttributeById,
    productCustomOptionsCanAddToCartHandler,
  };
});

export default useProductStore;
