import { ComposableFunctionArgs, Context } from '@vue-storefront/core';
import logger from '~/utilities/logger';

export const loadTotalQtyCommand = {
  execute: async (context: Context, params?: ComposableFunctionArgs<{}>) => {
    logger.debug('[Magento]: Load cart total qty');

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId()?.indexOf('|') ? apiState.getCartId().split('|').pop() : apiState.getCartId();

    if (cartId) {
      const { data }: any = await context.$magento.api.cartTotalQty(cartId, params?.customQuery ?? null);

      return data?.cart?.total_quantity ?? 0;
    }

    return 0;
  },
};
