import { NuxtAppOptions } from '@nuxt/types';
import { encodeBase64 } from './base64';

type ExponeaAuthentication = {
  projectToken: string;
  username: string;
  password: string;
  authKey: string;
};

function resolveAuthentication(app: NuxtAppOptions): ExponeaAuthentication {
  let auth = <ExponeaAuthentication>{};

  if (app.$config?.exponea?.[app.i18n.locale]) {
    auth = app.$config?.exponea[app.i18n.locale];
  } else {
    auth = app.$config?.exponea?.[app.i18n.defaultLocale];
  }

  // Generate authKey using "username:password" as its input and base64 encode it:
  auth.authKey = encodeBase64(`${auth.username}:${auth.password}`);

  return auth;
}

export default (app: NuxtAppOptions, inject) => {
  inject('exponeaAuthenticationResolver', {
    resolve: () => resolveAuthentication(app),
  });
};
