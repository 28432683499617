import { ref } from '@nuxtjs/composition-api';
import { isMenu, Component, Page, Content } from '@bloomreach/spa-sdk';

type Menu = Omit<Content, 'getData'>;
export const useMenu = (props: { component: Component; page: Page }) => {
  const menuRef = props.component.getModels()?.menu;
  const menuContent: Menu = menuRef && props.page.getContent(menuRef);
  const menu = ref(isMenu(menuContent) ? menuContent : undefined);

  return {
    menu,
  };
};

export default useMenu;
