import { useContext } from '@nuxtjs/composition-api';
import { GetProductSearchParams } from '@vue-storefront/magento-api';

type UseContextReturn = ReturnType<typeof useContext>;

export const getProductDetailsCommand = {
  execute: async (
    context: UseContextReturn,
    searchParams: GetProductSearchParams,
    customQuery = { productDetail: 'productDetail' },
  ) => {
    const { data } = await context.app.$vsf.$magento.api.productDetail(searchParams, customQuery);

    return data?.products ?? null;
  },
};
