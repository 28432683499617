import { usePageStore } from '~/stores';

export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    const {
      routeData: { type },
    } = usePageStore();
    /*
    We want to handle the scrolling manually when we are navigating in vsf.
    Chrome seems not care if the value has changed during vsf navigation, hence it will always see 'manual'
    Safari on the other hand seems to see the value as 'auto' and will scroll
     */
    history.scrollRestoration = 'manual';

    if (['CATEGORY', 'SEARCH'].includes(type)) return next();

    const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'));
    sessionStorage.setItem(
      'scrollPositions',
      JSON.stringify({
        ...scrollPositions,
        [from.fullPath]: window.scrollY,
      }),
    );
    next();
  });
};
