export default `
  query wishlist($currentPage: Int = 1, $pageSize: Int = 50) {
    customer {
      wishlists {
        items_count
        items_v2(currentPage: $currentPage, pageSize: $pageSize) {
          items {
            id
            quantity
            product {
              name
              sku
            }
          }
        }
      }
    }
  }
`;
