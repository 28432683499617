import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import { UpdateCartItemsInput } from '@vue-storefront/magento-api';
import logger from '~/utilities/logger';

export const updateItemQtyCommand = {
  execute: async (
    context: Context,
    { currentCart, product, quantity, customQuery = { updateCartItems: 'updateCartItems' } },
  ) => {
    logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

    const { data, errors } = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

    logger.debug('[Result]:', { data, errors });

    if (!data?.updateCartItems && errors?.length) {
      throw errors[0];
    }

    return data.updateCartItems.cart as Cart;
  },
};
