// https://staging-suggest.dxpapi.com/api/v2/suggest/?
// account_id=6695
// &auth_key=8clkfyt5d9x79qj4
// &request_id=5757996310600
// &ref_url=http://localhost:3000
// &url=http://localhost:3000/search?q=bade
// &catalog_views=myjewellery_de
// &request_type=suggest
// &q=bik

import { ref } from '@vue/composition-api';
import axios from 'axios';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';

export interface AutosuggestQuery {
  _br_uid_2?: string;
  account_id: number;
  auth_key: string;
  request_id: number;
  ref_url: string;
  url: string;
  catalog_views: string;
  request_type: string;
  q: string;
}

export interface AutosuggestAttributeSuggestion {
  name: string;
  value: string;
  attributeType: string;
  query?: string;
  displayText?: string;
}

export interface AutosuggestSearchSuggestion {
  sale_price: number;
  sku: string;
  original_price: number;
  hover_image_url: string;
  pid: string | number;
  url: string;
  title: string;
  thumb_image: string;
  thumbnail: string;
  variants: any[];
}

export interface AutosuggestResponse {
  queryContext: {
    originalQuery: string;
  };
  suggestionGroups: [
    {
      catalogName: string;
      view: string;
      querySuggestions: [
        {
          query: string;
          displayText: string;
        },
      ];
      attributeSuggestions: AutosuggestAttributeSuggestion[];
      searchSuggestions: AutosuggestSearchSuggestion[];
    },
  ];
}

const parseResponseData = (data: any) => {
  let parsedData = data;

  parsedData.suggestionGroups?.[0]?.searchSuggestions.forEach((doc, index: number) => {
    parsedData.suggestionGroups[0].searchSuggestions[index] = {
      ...doc,
      hover_image_url: transformImageUrlToSize(doc.hover_image_url, ImageSize.Default, 'bloomreach'),
      thumb_image: transformImageUrlToSize(doc.thumb_image, ImageSize.Default, 'bloomreach'),
      thumbnail: transformImageUrlToSize(doc.thumbnail, ImageSize.Default, 'bloomreach'),
    };
  });

  return parsedData;
};

export const useBloomreachAutosuggestApi = (app: {
  $config: { [key: string]: any };
  i18n: { [key: string]: any };
  $cookies: Map<string, string>;
}) => {
  const loading = ref<boolean | null>(null);
  const results = ref<AutosuggestResponse | null>(null);
  const requestId = Math.floor(1000000000000 + Math.random() * 9000000000000);
  const { stagingEnabled, accountId, authKey, domainKey } = app.$config.bloomreachDiscovery;

  const domainKeys = Object.fromEntries(domainKey.split(',').map((k) => k.split('=')));
  const currentDomainKey = domainKeys[app.i18n.locale?.substring(0, 2)] || Object.values(domainKeys)[0];

  const search = async (q: string) => {
    loading.value = true;

    const query: AutosuggestQuery = {
      account_id: accountId,
      auth_key: authKey,
      request_id: requestId,
      catalog_views: currentDomainKey,
      ref_url: `${document.location.protocol}//${document.location.hostname}:${document.location.port}`,
      url: `${document.location.protocol}//${document.location.hostname}:${document.location.port}`,
      request_type: 'suggest',
      q,
    };

    const brID = app.$cookies?.get('_br_uid_2');
    if (brID) {
      query._br_uid_2 = encodeURIComponent(brID);
    }

    const queryString = Object.keys(query)
      .map((key) => {
        return `${key}=${encodeURIComponent(query[key])}`;
      })
      .join('&');

    results.value = await axios
      .get(`https://${stagingEnabled ? 'staging-' : ''}suggest.dxpapi.com/api/v2/suggest/?${queryString}`)
      .then((res) => parseResponseData(res.data))
      .catch((error) => {
        return {
          success: false,
          data: [],
        };
      });

    loading.value = false;
  };

  return {
    search,
    loading,
    results,
  };
};

export default useBloomreachAutosuggestApi;
