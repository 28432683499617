/**
 * Gets the facets (pretty urls) from Bloomreach CMS these should match exactly (case sensitive) with the facets from Bloomreach Discovery.
 * @returns {Promise<PrettyUrlFilter[]>} The facets matched with the pretty URL filters.
 * @throws {Error} If failed to fetch the pretty URL facets.
 */

import useCache from '~/composables/useCache';
import { useBloomreachDiscoveryStore } from '~/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { PrettyUrlFilter } from '~/stores/useBloomreachDiscoveryStore';
import logger from '~/utilities/logger';

export default async (context) => {
  const { get: getCache, set: setCache } = useCache('facets');
  const { $brxmEndpointResolver, app } = context;
  const locale = app.i18n.locale;
  const { prettyUrls } = storeToRefs(useBloomreachDiscoveryStore());

  const facets = getCache(`facets_${locale}`);

  if (facets) {
    logger.info(`[loadPrettyUrlFilters] Serving from cache facets_${locale}`);
    prettyUrls.value = facets;
    return facets;
  }

  try {
    const { data } = await axios.get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/facets/facets`);

    // Get the facet $refs from 'prettyUrlItems'
    const prettyUrlRefs =
      data?.content[data?.document?.$ref.substring(9)].data?.prettyUrlItems?.map((item) =>
        item.$ref.split('/').pop(),
      ) || [];

    // Get the facets object by reference and map to useful values
    const facets = prettyUrlRefs.map((ref) => {
      const facet = data?.content?.[ref]?.data;
      // Facet type and value are in one string: "key=value" (e.g. "colors=black")
      const facetTypeAndValue = facet.facetValue?.split('=');

      return <PrettyUrlFilter>{
        label: facetTypeAndValue?.[1] || facet.facetValue,
        slug: facet.prettyUrl,
        type: facetTypeAndValue?.[0] || 'default',
      };
    });

    if (facets?.length < 1) {
      throw new Error('[loadPrettyUrlFilters] No facets found');
    }

    setCache(`facets_${locale}`, facets);

    logger.info(`[loadPrettyUrlFilters] Got ${facets.length} facets for ${locale}`);

    prettyUrls.value = facets;
  } catch (err) {
    throw new Error('[loadPrettyUrlFilters] Failed to fetch pretty url facets');
  }
};
