import cartFields from './cartFragment.gql';

/** GraphQL query to get the cart of the current logged in customer */
export default `
  query customerCartComplete {
    customerCart {
      ...cartFields
      categories {
        uid
        name
        url_suffix
        url_path
        breadcrumbs {
          category_name
          category_url_path
        }
      }
      review_count
      reviews {
        items {
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
        selected_shipping_method {
          carrier_code
          carrier_title
          method_code
          method_title
          amount {
            value
            currency
          }
        }
      }
      billing_address {
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
      }
    }
  }
  ${cartFields}
`;
