import { ComposableFunctionArgs, Context } from '@vue-storefront/core';
import logger from '~/utilities/logger';

export type CustomerProductReviewParams = {
  pageSize: number;
  currentPage: number;
};

export const loadCustomerReviewsCommand = {
  execute: async (context: Context, params?: ComposableFunctionArgs<CustomerProductReviewParams>) => {
    logger.debug('[Magento] load customer review based on:', { params });

    const { data } = await context.$magento.api.customerProductReview(params, params?.customQuery ?? null);

    logger.debug('[Result]:', { data });

    return data?.customer ?? {};
  },
};
