import { defineStore } from 'pinia';
import { Breadcrumb } from '~/types/interfaces/breadcrumbs';
import { computed, reactive, toRefs, useContext, useRoute } from '@nuxtjs/composition-api';

export const useBreadcrumbsStore = defineStore('breadcrumbs', () => {
  const route = useRoute();
  const { app, localePath, $axios, $brxmEndpointResolver } = useContext();

  const state = reactive({
    breadcrumbs: <Breadcrumb[]>[],
  });

  const fullPaths = computed(() => {
    const currentPath = route.value.fullPath
      .replace('/' + app.i18n.locale, '')
      .split('?')
      .shift();
    const paths = currentPath
      .replace('_index_', '')
      .split('/')
      .filter((p) => p !== '_index_')
      .filter((path) => path?.length);
    return paths.map((_slug, index) => paths.slice(0, index + 1).join('/'));
  });

  const unslugify = (slug: string) => {
    const result = slug.replace(/\-/g, ' ');
    return result.replace(/\w\S*/g, (s) => {
      return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase().replace('.html', '');
    });
  };

  const fetchPagePath = async (path: string) => {
    const storedItem = state.breadcrumbs.find((breadcrumb) => breadcrumb.link === path);
    if (storedItem) return storedItem;

    return $axios
      .get(`${$brxmEndpointResolver.resolvePagesEndPoint()}/${path}`)
      .then((res) => {
        const item = {
          success: true,
          link: localePath(`/${path}`),
          text: res.data.page[res.data.document.$ref.substring(6)].data.displayName,
        };

        state.breadcrumbs.push(item);
      })
      .catch((error) => {
        const lastSlug = path.split('/').pop();
        const item = {
          success: false,
          link: '', // localePath(`/${path}`), // Brings you to a 404 page
          text: unslugify(lastSlug),
        };

        state.breadcrumbs.push(item);
      });
  };

  const autoGenerateBreadcrumbs = async (isMobile: boolean) => {
    if (isMobile) return fetchPagePath(fullPaths.value[0]);

    for (const path of fullPaths.value) {
      await fetchPagePath(path);
    }
  };

  return {
    fullPaths,
    ...toRefs(state),
    autoGenerateBreadcrumbs,
  };
});

export default useBreadcrumbsStore;
