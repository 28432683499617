interface Logger {
  debug: (message: string, ...meta: any[]) => void;
  info: (message: string, ...meta: any[]) => void;
  warn: (message: string, ...meta: any[]) => void;
  error: (message: string, ...meta: any[]) => void;
}

let winston;

if (process.server) {
  const { createLogger, format, transports } = require('winston');
  const { combine, timestamp } = format;
  winston = createLogger({
    level: 'info',
    format: combine(timestamp(), format.json()),
  });

  winston.add(new transports.Console());
}

const logger: Logger = {
  debug: (message, ...meta) => {
    if (process.server) {
      winston.debug(message, ...meta);
    } else {
      console.debug(message, ...meta);
    }
  },
  info: (message, ...meta) => {
    if (process.server) {
      winston.info(message, ...meta);
    } else {
      console.info(message, ...meta);
    }
  },
  warn: (message, ...meta) => {
    if (process.server) {
      winston.warn(message, ...meta);
    } else {
      console.warn(message, ...meta);
    }
  },
  error: (message, params) => {
    if (process.server) {
      winston.error(message, params);
    } else {
      console.error(message, params);
    }
  },
};

export default logger;
