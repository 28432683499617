import { defineStore } from 'pinia';
import type { Product } from '@/types/interfaces/product';
import { computed, reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import { Facet, ParsedSearchResponse } from '~/composables/useBloomreachDiscoveryApi/bloomreachDiscoveryApi';
import { useBloomreachDiscoveryApi } from '~/composables';
import { usePageStore } from '~/stores';
import { useUiState } from '~/stores';
import getFiltersFromRoutePath from './helpers/getFiltersFromRoutePath';
import loadPrettyUrlFilters from './helpers/loadPrettyUrlFilters';
import setFilterPath from './helpers/setFilterPath';
import logger from '~/utilities/logger';

export const facetIds = [
  'original_price',
  'price',
  'colors',
  'sizes',
  'campaign',
  'fit',
  'material',
  'season',
  'style_profile',
  'trends',
];
const facetsPreferredOrder = ['price', 'colors', 'sku_color', 'sizes'];
const facetsToIgnore = ['category', 'brand', 'sale', 'letter', 'original_price', 'sale_price', 'phone_type'];
const facetsKeysMap = {
  sale_price: 'price',
};
export const doSearchOperations = {
  PREPEND: 'PREPEND',
  APPEND: 'APPEND',
};

export const nonPrettyUrlFacets = ['price'];
export interface PrettyUrlFilter {
  label: string;
  slug: string;
  type: string;
}

export const useBloomreachDiscoveryStore = defineStore('bloomreachDiscovery', () => {
  const context = useContext();
  const { app, $config, i18n, route, redirect } = context;
  const { closeFilterSidebar } = useUiState();
  const { searchProducts } = useBloomreachDiscoveryApi({ $config, i18n, $cookies: app.$cookies });

  const state = reactive({
    loading: <boolean>false,
    searchResponse: <ParsedSearchResponse>null,
    selectedFilters: <Record<string, any>>{},
    appliedFilters: <Record<string, any>>{},
    selectedSortOption: <string>route.value.query?.product_list_order?.toString() || '',
    itemsPerPage: <number>28,
    prependedPage: <number>1,
    currentPage: <number>1,
    products: <Product[]>[],
    productsAmount: <number>0,
    responsePage: <number>1,
    previousPage: <number>1,
    campaigns: <Record<string, any>>{},
    currentCampaign: <Record<string, any>>{},
    prettyUrls: <PrettyUrlFilter[]>[],
    selectedPrettyUrls: [],
    breadcrumbs: <string[]>[],
  });

  const productsInSearchResponse = computed(() => (state.searchResponse?.response?.docs as Product[]) || []);

  const sortOptions = computed(() => {
    return [
      {
        name: i18n.t('Recommended'),
        value: '',
      },
      {
        name: i18n.t('Newest'),
        value: 'newest',
      },
      {
        name: i18n.t('Lowest price'),
        value: 'price',
      },
      {
        name: i18n.t('Highest price'),
        value: 'price_high',
      },
      {
        name: i18n.t('Name A-Z'),
        value: 'name',
      },
    ];
  });
  const formatAndSortFacetOptions = (facet: Facet) => {
    if (facet.type === 'number_range') {
      return (
        facet.value?.map((value) => ({
          ...value,
          label: value.end,
        })) || []
      );
    }
    if (facet.name === 'month' || facet.name === 'month_flower') {
      const formatter = new Intl.DateTimeFormat(app.i18n.locale, { month: 'long' });
      const months = Array.from(Array(12).keys()).map((k) => formatter.format(new Date(1970, k, 1)));
      return (
        facet.value
          ?.sort((a, b) => months.indexOf(a?.name) - months.indexOf(b?.name))
          ?.map((o) => ({
            ...o,
            label: o.name?.charAt(0).toUpperCase() + o.name?.slice(1),
          })) || []
      );
    } else {
      return (
        facet.value?.map((o) => ({
          ...o,
          label: o.name,
        })) || []
      );
    }
  };

  const facets = computed(() => {
    return state.searchResponse?.facet_counts?.facets
      ?.filter((facet) => !facetsToIgnore.includes(facet.name))
      .sort(
        (a, b) =>
          (facetsPreferredOrder.indexOf(a.name) > -1
            ? facetsPreferredOrder.indexOf(a.name)
            : facetsPreferredOrder.length) -
          (facetsPreferredOrder.indexOf(b.name) > -1
            ? facetsPreferredOrder.indexOf(b.name)
            : facetsPreferredOrder.length),
      )
      .map((facet) => {
        return {
          id: facet.name,
          label: i18n.t((facet.name[0].toUpperCase() + facet.name.slice(1)).replace('_', ' ').replace('Sizes', 'Size')),
          type: facet.type,
          options: formatAndSortFacetOptions(facet),
        };
      })
      .filter((f) => (Array.isArray(f.options) ? f.options.length : f.options));
  });

  const pagination = computed(() => ({
    currentPage: state.currentPage,
    prependedPage: state.prependedPage,
    totalProducts: state.productsAmount || 0,
    currentProducts: state.products?.length || 0,
    itemsPerPage: state.itemsPerPage,
    totalPages: Math.ceil(state.productsAmount / state.itemsPerPage) || 1,
  }));

  const getCampaign = async (query: string, isCategoryId: boolean) => {
    const { campaign } = await searchProducts({
      search_type: isCategoryId ? 'category' : 'keyword',
      q: query,
      rows: 0,
      start: 1,
      sort: '',
      fq: {},
    });

    if (campaign) {
      state.campaigns[query] = campaign;
    } else {
      state.campaigns[query] = false;
    }
  };

  // Mutations
  const doSearch = async ({ page = state.currentPage, setProducts = false }): Promise<void> => {
    const { routeData } = usePageStore();

    if (setProducts) {
      state.loading = true;

      clearFilters();
      await loadPrettyUrlFilters(context);

      getFiltersFromRoutePath(context);

      state.currentPage = state.prependedPage = parseInt(route.value?.query?.p?.toString()) || 1;
    }

    const query = route.value?.query?.q || routeData.query?.q;
    state.previousPage = state.searchResponse?.response?.start / state.itemsPerPage + 1 || 1;

    if (JSON.stringify(state.appliedFilters) !== JSON.stringify(state.selectedFilters)) {
      state.currentPage = 1;
    }

    const categoryId = routeData?.id?.toString();

    if (
      ['CATEGORY', 'SEARCH'].includes(routeData.type) &&
      (categoryId || query) &&
      state.campaigns[categoryId || query] === undefined
    ) {
      await getCampaign(categoryId || query, !!categoryId);
    }

    state.currentCampaign = state.campaigns[categoryId || query];

    state.itemsPerPage = state.campaigns[categoryId || query] ? 27 : 28;

    state.searchResponse = await searchProducts({
      search_type: query ? 'keyword' : 'category',
      q: query || categoryId,
      rows: state.itemsPerPage,
      start: (page - 1) * state.itemsPerPage,
      sort: state.selectedSortOption || '',
      fq: state.selectedFilters,
    });

    if (!state.searchResponse.response?.numFound) {
      if (routeData.type === 'SEARCH') {
        logger.warn('[doSearch] No result for search: ' + route.value.fullPath);
      } else if (routeData.query || routeData.has_pretty_filters) {
        logger.warn('[doSearch] No products for page with filters: ' + route.value.fullPath);
      } else {
        logger.warn('[doSearch] No products for page: ' + route.value.fullPath);
      }
    }

    if (state.searchResponse.response?.numFound) {
      state.searchResponse.response.docs.forEach((product) => (product.page = page));
    }

    if (state.searchResponse?.keywordRedirect) {
      const url = state.searchResponse?.keywordRedirect['redirected url'];
      redirect(!url.includes('http') ? `https://${url}` : url);
    }

    state.responsePage = state.searchResponse?.response?.start / state.itemsPerPage + 1 || 0;

    if (setProducts) {
      state.products = productsInSearchResponse.value;
      state.productsAmount = state.searchResponse?.response?.numFound || 0;
    }

    state.loading = false;
  };

  const applyFilters = async (moreProducts = null) => {
    closeFilterSidebar();
    await setFilterPath(context, moreProducts);

    state.appliedFilters = JSON.parse(JSON.stringify(state.selectedFilters));

    if (moreProducts === doSearchOperations.APPEND) {
      state.products = [...state.products, ...productsInSearchResponse.value];
    } else if (moreProducts === doSearchOperations.PREPEND) {
      state.products = [...productsInSearchResponse.value, ...state.products];
    } else {
      state.products = productsInSearchResponse.value;
    }
    state.productsAmount = state.searchResponse?.response?.numFound || 1;

    state.previousPage = state.responsePage;
  };

  const clearFilters = () => {
    state.appliedFilters = {};
    state.selectedFilters = {};
  };

  return {
    ...toRefs(state),
    facetsKeysMap,
    sortOptions,
    facets,
    pagination,
    doSearch,
    applyFilters,
    clearFilters,
    getFiltersFromRoutePath,
  };
});

export default useBloomreachDiscoveryStore;
