import { CategorySearchQuery } from '@vue-storefront/magento-api';

export const getCategoryListCommand = {
  execute: async (context, params): Promise<CategorySearchQuery[]> => {
    const { data } = await context.app.$vsf.$magento.api.categoryList({ filters: params });

    return (
      data?.categoryList?.map((category) => ({
        ...category,
        url: context.app.localePath(`/${category.url_path}${category.url_suffix}`),
      })) || []
    );
  },
};
