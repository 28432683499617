import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import { useCartStore } from '~/stores';
import logger from '~/utilities/logger';

export const removeCouponCommand = {
  execute: async (context: Context, { currentCart }) => {
    logger.debug('[Magento]: Remove coupon from cart', { currentCart });

    const { data, errors } = await context.$magento.api.removeCouponFromCart({
      cart_id: currentCart.id,
    });

    const cartStore = useCartStore();
    cartStore.setCart(data.removeCouponFromCart.cart);

    logger.debug('[Result]:', { data });

    return {
      updatedCart: data.removeCouponFromCart?.cart as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
