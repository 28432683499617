import { defineStore } from 'pinia';
import type { Customer } from '@vue-storefront/magento-api';
import { reactive, toRefs } from '@nuxtjs/composition-api';

interface CustomerState {
  user: Customer | null;
  isLoggedIn: boolean;
}

export const useCustomerStore = defineStore('customer', () => {
  const state: CustomerState = reactive({
    user: null,
    isLoggedIn: false,
  });

  const setIsLoggedIn = (isLoggedIn: boolean) => (state.isLoggedIn = isLoggedIn);
  const setUser = (user: Customer) => (state.user = user);

  const getTelephone = () => state.user?.addresses?.[0]?.telephone;

  return {
    ...toRefs(state),
    setIsLoggedIn,
    setUser,
    getTelephone,
  };
});

export default useCustomerStore;
