import _debounce from '~/helpers/debounce';
import { useContext } from '@nuxtjs/composition-api';

export const usePaazlApi = (context = null) => {
  const { $axios, $config } = context || useContext();
  const baseUrl = `${$config.paazlBaseUrl}/v1`;

  const get = async (url: string, requiresSecret = true) => {
    const response = await $axios.get(baseUrl + url, { headers: authHeader(requiresSecret) });
    return handleResponse(response);
  };

  const post = async (url: string, data, requiresSecret = true) => {
    try {
      const response = await $axios.post(baseUrl + url, data, { headers: authHeader(requiresSecret) });
      return handleResponse(response);
    } catch (error) {
      return handleResponse(error.response);
    }
  };

  const authHeader = (requiresSecret: Boolean): any => {
    const header = {
      'content-type': 'application/json',
      Authorization: `Bearer ${$config.paazlApiKey}`,
    };

    if (requiresSecret) {
      header.Authorization = `Bearer ${$config.paazlApiKey}:${$config.paazlApiSecret}`;
    }
    return header;
  };

  const handleResponse = (response) => {
    const data = response.data;
    if (response.status === 404) {
      throw [response.statusText];
    }
    if (response.status !== 200) {
      throw data?.errors || [response.statusText];
    }
    return data;
  };

  return {
    get,
    post,
  };
};

export default usePaazlApi;
