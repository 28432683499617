import { ComposableFunctionArgs, Context } from '@vue-storefront/core';
import type { CreateProductReviewInput } from '@vue-storefront/magento-api';
import logger from '~/utilities/logger';

export const addReviewCommand = {
  execute: async (context: Context, params: ComposableFunctionArgs<CreateProductReviewInput>) => {
    logger.debug('[Magento] add review params input:', JSON.stringify(params, null, 2));

    const { customQuery, ...input } = params;

    const { data } = await context.$magento.api.createProductReview(input, params?.customQuery ?? null);

    logger.debug('[Result]:', { data });

    return data?.createProductReview?.review ?? {};
  },
};
