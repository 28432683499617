import { computed, reactive } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

export interface UiState {
  isCartSidebarOpen: boolean;
  isFilterSidebarOpen: boolean;
  isLocaleSidebarOpen: boolean;
  isProductReviewsSidebarOpen: boolean;
  isWishlistSidebarOpen: boolean;
  isLoginModalOpen: boolean;
  isNewsletterModalOpen: boolean;
  isCategoryGridView: boolean;
  isWishlistGridView: boolean;
  isMobileMenuOpen: boolean;
  isProductLightboxOpen: boolean;
  isHeaderNotificationVisible: boolean;
  isTopHeaderVisible: boolean;
  soldOutForm: {
    productId?: number | null;
    optionLabel?: string | null;
    type?: string | null;
    isModalVisible?: boolean;
  };
  currentSlideIndex?: number | null;
}

const useUiState = defineStore('uiState', () => {
  const state: UiState = reactive({
    isCartSidebarOpen: false,
    isFilterSidebarOpen: false,
    isLocaleSidebarOpen: false,
    isProductReviewsSidebarOpen: false,
    isWishlistSidebarOpen: false,
    isLoginModalOpen: false,
    isNewsletterModalOpen: false,
    isCategoryGridView: true,
    isWishlistGridView: false,
    isMobileMenuOpen: false,
    isHeaderNotificationVisible: false,
    isProductLightboxOpen: false,
    isTopHeaderVisible: true,
    soldOutForm: {
      productId: null,
      optionLabel: null,
      type: null,
      isModalVisible: false,
    },
    currentSlideIndex: 0,
  });

  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isLoginModalOpen = computed(() => state.isLoginModalOpen);
  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen);
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);

  const isWishlistGridView = computed(() => state.isWishlistGridView);

  const isHeaderNotificationVisible = computed(() => state.isHeaderNotificationVisible);
  const showHeaderNotification = () => {
    state.isHeaderNotificationVisible = true;
  };
  const hideHeaderNotification = () => {
    state.isHeaderNotificationVisible = false;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };
  const closeFilterSidebar = () => {
    state.isFilterSidebarOpen = false;
  };

  const isLocaleSidebarOpen = computed(() => state.isLocaleSidebarOpen);
  const toggleLocaleSidebar = () => {
    state.isLocaleSidebarOpen = !state.isLocaleSidebarOpen;
  };

  const isProductReviewsSidebarOpen = computed(() => state.isProductReviewsSidebarOpen);
  const toggleProductReviewsSidebar = () => {
    state.isProductReviewsSidebarOpen = !state.isProductReviewsSidebarOpen;
  };

  const isProductLightboxOpen = computed(() => state.isProductLightboxOpen);
  const toggleProductLightBox = () => {
    state.isProductLightboxOpen = !state.isProductLightboxOpen;
  };

  const isTopHeaderVisible = computed(() => state.isTopHeaderVisible);
  const toggleTopHeader = (value: boolean) => {
    if (value !== undefined) return (state.isTopHeaderVisible = value);
    state.isTopHeaderVisible = !state.isTopHeaderVisible;
  };

  const soldOutForm = computed(() => state.soldOutForm);
  const setSoldOutFormData = (params: {
    productId?: number;
    optionLabel?: string;
    type?: 'soldOut' | 'comingSoon';
    isModalVisible?: boolean;
  }) => {
    state.soldOutForm = {
      ...state.soldOutForm,
      ...params,
    };
  };

  const currentSlideIndex = computed({
    get() {
      return state.currentSlideIndex;
    },
    set(value: number) {
      state.currentSlideIndex = value;
    },
  });

  const closeAllSidebars = () => {
    state.isCartSidebarOpen = false;
    state.isFilterSidebarOpen = false;
    state.isLocaleSidebarOpen = false;
    state.isProductReviewsSidebarOpen = false;
    state.isWishlistSidebarOpen = false;
  };

  return {
    isLoginModalOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isWishlistGridView,
    isMobileMenuOpen,
    isHeaderNotificationVisible,
    isCartSidebarOpen,
    isFilterSidebarOpen,
    isLocaleSidebarOpen,
    isProductReviewsSidebarOpen,
    isWishlistSidebarOpen,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleLoginModal,
    toggleNewsletterModal,
    toggleCartSidebar,
    toggleFilterSidebar,
    closeFilterSidebar,
    toggleLocaleSidebar,
    toggleProductReviewsSidebar,
    toggleWishlistSidebar,
    toggleMobileMenu,
    showHeaderNotification,
    hideHeaderNotification,
    closeAllSidebars,
    soldOutForm,
    setSoldOutFormData,
    isTopHeaderVisible,
    toggleTopHeader,
    isProductLightboxOpen,
    toggleProductLightBox,
    currentSlideIndex,
  };
});

export default useUiState;
