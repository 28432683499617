import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import { getProductListCommand } from './commands/getProductListCommand';
import { getProductDetailsCommand } from './commands/getProductDetailsCommand';
import { GetProductSearchParams, ProductInterface } from '@vue-storefront/magento-api';

import type { MJProductDetails, ProductList, UseProductErrors, UseProductInterface } from './useProduct';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string, theContext?): UseProductInterface {
  let context;
  try {
    context = useContext();
  } catch (e) {
    context = theContext;
  }
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  });

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<MJProductDetails | null> => {
    logger.debug(`useProduct/${id}/getProductDetails`, searchParams);

    try {
      const product: MJProductDetails = await getProductDetailsCommand.execute(context, searchParams);
      error.value.getProductDetails = null;

      return product;
    } catch (err) {
      error.value.getProductDetails = err;
      logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getProductList,
    getProductDetails,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;
