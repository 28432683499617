import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import { placeOrderCommand } from './commands/placeOrderCommand';
import useCart from '~/composables/useCart';
import type { PlaceOrderOutput } from '@vue-storefront/magento-api';
import type { UseMakeOrderErrors, UseMakeOrderInterface } from './useMakeOrder';
import { ComposableFunctionArgs } from '@vue-storefront/core';

/**
 * Allows making an order from a cart.
 *
 * See the {@link UseMakeOrderInterface} for a list of methods and values available in this composable.
 */
export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart, load: loadCart } = useCart();
  const context = useContext();

  const make = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    logger.debug('useMakeOrder.make');
    let placedOrder = null;
    try {
      loading.value = true;
      placedOrder = await placeOrderCommand.execute(context, cart.value.id, params);
      loadCart();
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }
    return placedOrder;
  };

  return {
    make,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useMakeOrder';
export default useMakeOrder;
