import { StoreConfig } from '@vue-storefront/magento';
import { ref, computed, useContext, useRoute } from '@nuxtjs/composition-api';
import cookieNames from '~/enums/cookieNameEnum';

export const useConfig = () => {
  const { app } = useContext();
  const route = useRoute();
  const config = ref(null);

  const loadConfig = async () => {
    const selectedLocale = ref<string | undefined>(
      route.value.path?.substring(1)?.split('/')?.shift().substring(0, 2) ??
        app.$cookies.get(cookieNames.localeCookieName),
    );

    if (config.value === null) {
      const storeConfig: StoreConfig[] = require('./storeConfig.json');
      const selectedStore = storeConfig.find((store) => store.locale === selectedLocale.value);
      config.value = selectedStore || storeConfig[0];
    }
  };

  return {
    config,
    loadConfig,
  };
};

export default useConfig;
