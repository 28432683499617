import { defineStore } from 'pinia';
import { reactive, toRefs } from '@nuxtjs/composition-api';

export interface ContentState {
  content: {};
}

export const useContentStore = defineStore('content', () => {
  const state: ContentState = reactive({
    content: {},
  });

  return {
    ...toRefs(state),
  };
});

export default useContentStore;
