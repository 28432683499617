import { useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';

type Props = {
  option: Record<string, any>;
  productConfiguration: Record<string, any>;
  type?: 'wishlist' | 'cart';
  sku: string;
};

export default (props: Props) => {
  const { products } = storeToRefs(useProductStore());
  const product = products.value[props.sku];
  const keys = Object.keys(props.productConfiguration).sort((keyA, keyB) => {
    const positionA = product.configurable_options.find((option) => option.attribute_uid === keyA).position;
    const positionB = product.configurable_options.find((option) => option.attribute_uid === keyB).position;

    return positionA - positionB;
  });

  let previousValue;
  for (let key of keys) {
    if (key === props.option.attribute_uid) break;
    previousValue = props.productConfiguration[key];
  }

  return previousValue === null;
};
