import { reactive, toRefs } from '@nuxtjs/composition-api';
import type { ComposableFunctionArgs } from '@vue-storefront/core';
import logger from '~/utilities/logger';
import { getCategoryListCommand } from './commands/getCategoryListCommand';
import { defineStore } from 'pinia';
import { getCategoriesCommand } from '~/stores/useCategoryStore/commands/getCategoriesCommand';

export const useCategoryStore = defineStore('category', () => {
  const state = reactive({
    loading: false,
    error: { load: null },
    categoriesList: [],
  });

  const getCategoryList = async (context, params: ComposableFunctionArgs<{ [key: string]: any }>): Promise<any[]> => {
    logger.debug('categoryStore/load', { params });
    state.categoriesList = [];
    try {
      state.loading = true;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      state.categoriesList = await getCategoryListCommand.execute(context, params);

      logger.debug('[Result]:', { categoriesList: state.categoriesList });

      state.error.load = null;

      return state.categoriesList;
    } catch (err) {
      state.error.load = err;
      logger.error('categoryStore/load', err);
    } finally {
      state.loading = false;
    }
  };

  const getCategoryHrefLangs = async (context, categoryId: number) => {
    if (!categoryId) return;
    try {
      const categories = await getCategoriesCommand.execute(context, categoryId);

      if (categories?.[0]) {
        return categories[0]?.hreflang_data?.map((alt) => {
          return {
            hid: `alternate-${alt.language}`,
            rel: alt.rel,
            href: alt.url,
            url: alt.url,
            hreflang: alt.language,
            language: alt.language,
          };
        });
      }
    } catch (err) {
      state.error.load = err;
      logger.error('categoryStore/categories', err);
    }
  };

  return {
    ...toRefs(state),
    getCategoryList,
    getCategoryHrefLangs,
  };
});

export default useCategoryStore;
