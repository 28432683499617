import { CustomQuery } from '@vue-storefront/core';
import type { SetShippingMethodsOnCartInput, Cart } from '@vue-storefront/magento-api';

export const setShippingMethodsOnCartCommand = {
  execute: async (
    context,
    shippingMethodParams: SetShippingMethodsOnCartInput,
    customQuery: CustomQuery,
    customHeaders,
  ): Promise<Cart | null> => {
    const { data } = await context.app.$vsf.$magento.api.setShippingMethodsOnCart(
      shippingMethodParams,
      customQuery,
      customHeaders,
    );

    return (data?.setShippingMethodsOnCart?.cart as Cart) ?? null;
  },
};
