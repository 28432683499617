import Vue from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type { Wishlist } from '@vue-storefront/magento';
import { useUiNotification } from '~/composables';
import { reactive, ref, toRefs, useContext, watch } from '@nuxtjs/composition-api';
import type {
  UseWishlistAddItemParams,
  UseWishlistAddItemToCartParams,
  UseWishlistAfterAddingWishlistItemToCartParams,
  UseWishlistErrors,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistUpdateItemParams,
} from '~/stores/wishlistStore/wishlistStore';
import logger from '~/utilities/logger';
import { findItemOnWishlist } from '~/stores/wishlistStore/helpers/findItemOnWishlist';
import updateProductsInWishlistQuery from '~/customQueries/queries/updateProductsInWishlist.gql';
import addWishlistItemsToCartQuery from '~/customQueries/queries/addWishlistItemsToCart.gql';

import { useCustomerStore, useProductStore } from '~/stores';
import wishlistIds from '~/customQueries/queries/wishlistIds.gql';

export type WishlistIds = {
  items_count: number;
  items_v2: {
    items: {
      id: string;
      product: Record<string, any>;
      quantity: number;
    }[];
  };
};

export const wishlistStore = defineStore('wishlist', () => {
  const { app, localePath } = useContext();
  const { products, activeProductSku } = storeToRefs(useProductStore());
  const { isLoggedIn } = storeToRefs(useCustomerStore());
  const { send: sendNotification } = useUiNotification();

  const state = reactive({
    loading: <boolean>false,
    wishlistLoaded: <boolean>false,
    wishlist: <Wishlist>{ items_count: 0 },
    wishlistIds: <WishlistIds>{ items_count: 0, items_v2: { items: [] } },
    showWishlistModal: <boolean>false,
  });

  const error = ref<UseWishlistErrors>({
    addItem: null,
    addItemToCart: null,
    removeItem: null,
    updateItem: null,
    load: null,
    clear: null,
  });

  const loadWishlist = async (params?: UseWishlistLoadParams) => {
    logger.debug('useWishlist/load');

    try {
      state.loading = true;
      logger.debug('[Magento Storefront]: useWishlist.load params->', params);

      if (isLoggedIn.value) {
        const { data } = await app.$vsf.$magento.api.wishlist(params?.searchParams || { currentPage: 1, pageSize: 10 });

        logger.debug('[Result]:', { data });
        const loadedWishlist = data?.customer?.wishlists ?? [];
        if (loadedWishlist[0]) {
          [state.wishlist] = loadedWishlist;
        }
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      logger.error('useWishlist/load', err);
    } finally {
      state.loading = false;
    }

    return state.wishlist;
  };

  const loadWishlistIds = async () => {
    logger.debug('useWishlist/loadWishlistIds');

    try {
      state.loading = true;

      if (isLoggedIn.value) {
        const { data } = await app.$vsf.$magento.api.customQuery({
          query: wishlistIds,
        });

        logger.debug('[Result]:', { data });
        const loadedWishlist = data?.customer?.wishlists ?? [];
        if (loadedWishlist?.[0]) {
          state.wishlistIds = loadedWishlist[0];
        }
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      logger.error('useWishlist/loadWishlistIds', err);
    } finally {
      state.loading = false;
    }

    return state.wishlistIds;
  };

  const addItemToWishlist = async ({
    product,
    configuration = {},
    customQuery = { addProductsToWishlist: 'addProductsToWishlist' },
  }: UseWishlistAddItemParams) => {
    logger.debug('useWishlist/addItem', product);

    if (!isLoggedIn.value) {
      addProductToSessionStorage(configuration);
      app.$cookies.set('login_redirect', window.location.href);

      return sendNotification({
        id: Symbol('user_updated'),
        message: 'loginToAddToWishlist',
        link: localePath('/customer/account/login?redirectToReferer=1'),
        linkText: app.i18n.t('login page') as string,
        type: 'danger',
        icon: 'error',
        persist: false,
        title: 'Wishlist',
        timeToLive: 5000,
      });
    }

    try {
      state.loading = true;
      logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: state.wishlist,
        product,
        customQuery,
      });

      if (!state.wishlist) {
        await loadWishlist({});
      }

      const itemOnWishlist = findItemOnWishlist(state.wishlistIds, product);

      if (itemOnWishlist) {
        return;
      }

      const items = [];
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
          });
          break;
        }
        case 'ConfigurableProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
            selected_options: Object.values(configuration),
          });
          break;
        }
        case 'BundleProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
            entered_options: [],
          });
          break;
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          return logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
      }

      const { data } = await app.context.$vsf.$magento.api.addProductToWishList(
        {
          id: '0',
          items,
        },
        {
          addProductsToWishlist: 'addProductToWishList',
        },
      );

      loadWishlistIds();

      logger.debug('[Result]:', { data });

      sendNotification({
        id: Symbol('product_added_to_wishlist'),
        message: app.i18n.t('You added {product} to your wishlist.', { product: product.name }) as string,
        type: 'success',
        icon: 'check',
        persist: false,
      });

      state.wishlist = data?.addProductsToWishlist?.wishlist ?? {};
    } catch (err) {
      error.value.addItem = err;
      logger.error('useWishlist/addItem', err);
    } finally {
      state.loading = false;
    }
  };

  const updateItem = async ({ wishlistItems, customQuery = { wishlist: 'wishlist' } }: UseWishlistUpdateItemParams) => {
    const wishlistId = state.wishlist?.id;

    logger.debug('useWishlist/updateItem', wishlistItems);

    try {
      state.loading = true;
      logger.debug('[Magento Storefront]: useWishlist.updateItem params->', {
        currentWishlist: state.wishlist,
        wishlistItems,
        customQuery,
      });

      const { data, errors } = await app.$vsf.$magento.api.customMutation({
        mutation: updateProductsInWishlistQuery.loc.source.body,
        mutationVariables: { wishlistId, wishlistItems },
      });

      logger.debug('[Result]:', { data });
      error.value.updateItem = null;
      state.wishlist = data?.updateProductsInWishlist?.wishlist ?? {};
    } catch (err) {
      error.value.updateItem = err;
      logger.error('useWishlist/updateItem', err);
    } finally {
      state.loading = false;
    }
  };

  const removeItemFromWishlistIds = (id) => {
    const itemIndex = state.wishlistIds?.items_v2.items.findIndex((item) => item.id === id);
    state.wishlistIds?.items_v2.items.splice(itemIndex, 1);
    state.wishlistIds.items_count--;
  };

  const removeItem = async ({
    product,
    customQuery = { removeProductsFromWishlist: 'removeProductsFromWishlist' },
  }: UseWishlistRemoveItemParams) => {
    logger.debug('useWishlist/removeItem', product);

    try {
      state.loading = true;
      logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: state.wishlist,
        product,
        customQuery,
      });

      const itemOnWishlist = findItemOnWishlist(state.wishlistIds, product);
      const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist(
        {
          id: '0',
          items: [itemOnWishlist.id],
        },
        customQuery,
      );

      removeItemFromWishlistIds(itemOnWishlist.id);

      logger.debug('[Result]:', { data });
      error.value.removeItem = null;
      state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {};
    } catch (err) {
      error.value.removeItem = err;
      logger.error('useWishlist/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  const addItemToCart = async ({ wishlistItemIds, callback }: UseWishlistAddItemToCartParams) => {
    logger.debug('useWishlist/addItemToCart', wishlistItemIds);

    try {
      state.loading = true;
      logger.debug('[Magento Storefront]: useWishlist.addItemToCart params->', {
        currentWishlist: state.wishlist,
        wishlistItemIds,
      });

      if (!state.wishlist) {
        await loadWishlist({});
      }

      if (!isLoggedIn.value) {
        logger.error('Need to be authenticated to add a product to wishlist');
      }

      const wishlistId = state.wishlist?.id;

      const { data, errors } = await app.$vsf.$magento.api.customMutation({
        mutation: addWishlistItemsToCartQuery.loc.source.body,
        mutationVariables: { wishlistId, wishlistItemIds },
      });

      removeItemFromWishlistIds(wishlistItemIds[0]);

      callback?.(data.addWishlistItemsToCart.status);

      logger.debug('[Result]: addWishlistItemsToCart => ', { data, errors });

      state.wishlist = data?.addWishlistItemsToCart?.wishlist
        ? {
            ...data.addWishlistItemsToCart.wishlist,
            items_count: data.addWishlistItemsToCart.wishlist.items_v2.items.length,
          }
        : {};

      logger.debug('[Result]: wishlistStore after update => ', state.wishlist);
    } catch (err) {
      error.value.addItem = err;
      logger.error('useWishlist/addItemToCart', err);
    } finally {
      state.loading = false;
    }
  };

  const clear = async () => {
    logger.debug('useWishlist/clear');

    try {
      state.loading = true;
      error.value.clear = null;
      state.wishlist = { items_count: 0 };
    } catch (err) {
      error.value.clear = err;
      logger.error('useWishlist/clear', err);
    } finally {
      state.loading = false;
    }
  };

  const isInWishlist = ({ product }: UseWishlistIsInWishlistParams) => {
    const wishlistProduct = findItemOnWishlist(state.wishlistIds, product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const setWishlist = (newWishlist: Wishlist) => {
    state.wishlist = newWishlist ?? {};
    logger.debug('useWishlist/setWishlist', newWishlist);
  };

  const addProductToWishlist = (productConfiguration) => {
    return addItemToWishlist({
      product: products.value[activeProductSku.value],
      configuration: productConfiguration,
    });
  };

  const addProductToSessionStorage = (productConfiguration) => {
    sessionStorage.setItem(
      'addToWishlistAfterLogin',
      JSON.stringify({
        product: products.value[activeProductSku.value],
        configuration: productConfiguration,
      }),
    );
    // Vue / Magento hack - @todo remove when login page is Vue
    // When going to an external page router.beforeEach is not called, so we do its logic here
    const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'));
    sessionStorage.setItem(
      'scrollPositions',
      JSON.stringify({
        ...scrollPositions,
        [app.router.currentRoute.fullPath]: window.scrollY,
      }),
    );
  };

  const addToWishlistAfterLogin = () => {
    if (sessionStorage.addToWishlistAfterLogin && isLoggedIn.value) {
      void addItemToWishlist(JSON.parse(sessionStorage.addToWishlistAfterLogin));
    }

    delete sessionStorage.addToWishlistAfterLogin;
  };

  const removeMagentoLocalStorageKeys = () => {
    const keys = ['wishlist', 'wishlist-dynamic'];
    const storage = JSON.parse(localStorage['mage-cache-storage'] ?? '{}');
    keys.forEach((key) => delete storage[key]);
    localStorage['mage-cache-storage'] = JSON.stringify(storage);
  };

  // Remove Magento local storage keys on every wishlist mutation
  watch(state.wishlist, () => {
    removeMagentoLocalStorageKeys();
  });

  return {
    ...toRefs(state),
    isInWishlist,
    setWishlist,
    updateItem,
    removeItem,
    addItemToCart,
    loadWishlist,
    loadWishlistIds,
    addItemToWishlist,
    clear,
    addProductToWishlist,
    addToWishlistAfterLogin,
  };
});

export default wishlistStore;
