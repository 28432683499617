import logger from './../../utilities/logger';
import cache from 'memory-cache';

export const useCache = (ns: string = 'global') => {
  /**
   * Get cached value
   * @param key {string} Cache key
   * @returns {any} Cached value
   */
  const get = (key: string) => {
    const result = cache.get(`${ns}/${key}`);
    if (result) {
      logger.info('[MemoryCache] returning value from cache.', `${ns}/${key}`);
      return result;
    }
    logger.info('[MemoryCache] not found; returning null.', `${ns}/${key}`);
    return null;
  };

  /**
   * Set cache value
   * @param key {string} Cache key
   * @param value {any} Cache value
   * @param ttl {number} Time to live, in seconds (default: 20 minutes)
   */
  const set = (key: string, value: any, ttl: number = 20 * 60) => {
    logger.info('[MemoryCache] writing value to cache.', `${ns}/${key}`);
    cache.put(`${ns}/${key}`, value, ttl * 1000);
  };

  const del = (key: string) => {
    cache.del(`${ns}/${key}`);
  };

  return {
    cache,
    get,
    set,
    delete: del,
  };
};

export default useCache;
