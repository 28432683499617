export default (func: any, wait: number = 500) => {
  let debouncer = null;

  const debounced = (...params: any[]) => {
    clearTimeout(debouncer);
    debouncer = setTimeout(() => func(...params), wait);
  };

  debounced.cancel = () => clearTimeout(debouncer);

  return debounced;
};
