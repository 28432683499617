import { useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { useProductStore } from '~/stores';

export const getProductThumbnailHoverImage = (product) => {
  if (!product || !product.thumbnail_hover) {
    return null;
  }
  return product.thumbnail_hover;
};

export const getProductColourWheels = (product) => {
  if (!product) {
    return null;
  }

  if (product?.colour_wheels && product?.colour_wheels?.length) {
    return product.colour_wheels;
  }

  if (product?.configurable_options) {
    const color = product?.configurable_options?.find((option) => option.attribute_code === 'base_color');

    if (color?.values) {
      return color.values.map((c) => ({
        name: c.label,
        image: `${c.swatch_data?.value}`,
        url: product.url_rewrites?.[0]?.url,
      }));
    }
  }

  return null;
};

export const getProductOptionsNames = (product): string => {
  const { i18n } = useContext();
  if (!product) return '';

  const translatedLabels = {
    size: i18n.t('variantMatrixSize'),
    base_color: i18n.t('variantMatrixColor'),
    birth_year: i18n.t('variantMatrixBirthYear'),
    birth_month: i18n.t('variantMatrixBirthMonth'),
    zodiac_sign: i18n.t('variantMatrixZodiacSign'),
    initial: i18n.t('variantMatrixInitial'),
    giftcard_price: i18n.t('variantMatrixGiftcardPrice'),
    phone_type: i18n.t('variantMatrixPhoneModel'),
  };

  const names =
    product.configurable_options
      ?.filter((option) => option.values.length > 1 || option.attribute_code === 'phone_type')
      ?.map((option) => translatedLabels[option.attribute_code]) || [];
  if (product.options?.length) {
    names.push(i18n.t('variantMatrixEngraving'));
  }
  return names.join(' & ');
};

export const getSelectedProductVariant = (product, productConfiguration) => {
  if (!product) return null;
  // Returns variant product if there is one, otherwise the main product
  if (!productConfiguration) return product.sku;
  const selectedProduct = product.variants?.find((variant) =>
    Object.values(productConfiguration)?.every((option) =>
      variant.attributes.find((attribute) => attribute.uid === option),
    ),
  );
  return selectedProduct?.product || { ...product, id: product.pid };
};

export const getSelectedProductVariantByUid = (sku, productConfiguration, attributeUid, uid) => {
  const { products } = storeToRefs(useProductStore());
  const product = products.value[sku];
  const configuration = {
    ...productConfiguration,
    [attributeUid]: uid,
  };
  const selectedProduct = getSelectedProductVariant(product, configuration);
  return selectedProduct || { ...product, id: product.pid };
};

const productGetters = {
  getProductThumbnailHoverImage,
  getProductColourWheels,
  getProductOptionsNames,
  getSelectedProductVariant,
  getSelectedProductVariantByUid,
};

export default productGetters;
