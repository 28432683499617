import { defineStore } from 'pinia';
import { useExponeaApi } from '~/composables/useExponeaApi';
import { ExponeaProduct } from '~/types/interfaces/exponea';
import { GetProductSearchParams } from '@vue-storefront/magento-api';
import { useCustomerStore } from '~/stores';
import { reactive, ref, Ref, toRefs, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';

export class ExponeaUser {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  city: string;
  postcode: string;
  address: string;
  style: string;
  gender: string;
  jewellery_preference: string;
}

export interface ExponeaRecommendation {
  id: string;
  products: any[];
  exponeaProducts: ExponeaProduct[];
  loading: boolean;
}

export interface ExponeaSegmentation {
  id: string;
  value: string;
}

export interface ExponeaItemset {
  sku: string;
  products: [];
  exponeaProducts: ExponeaProduct[];
}

export interface ExponeaState {
  user: ExponeaUser;
  userLoading: boolean;
  recommendations: ExponeaRecommendation[];
  segmentations: ExponeaSegmentation[];
  products: ExponeaProduct[];
  itemsets: ExponeaItemset[];
}

export const useExponeaStore = defineStore('exponea', () => {
  const { app } = useContext();

  const state: ExponeaState = reactive({
    user: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      city: '',
      postcode: '',
      address: '',
      style: '',
      gender: '',
      jewellery_preference: '',
    },
    userLoading: true,
    recommendations: [],
    segmentations: [],
    products: [],
    itemsets: [],
  });

  const getRecommendationById = (id: string) => {
    const recommendations = state.recommendations.find((recommendation) => id === recommendation.id);
    if (!recommendations) return [];
    if (recommendations.products.length > 0) return recommendations.products;
    return []; // Don't return exponea products, because if they're not in Magento, they will give a 404
  };
  const getRecommendationLoadingStateById = (id: string) => {
    const recommendations = state.recommendations.find((recommendation) => id === recommendation.id);
    return recommendations?.loading === true;
  };
  const getExponeaRecommendationById = (id: string) =>
    state.recommendations.find((recommendation) => id === recommendation.id)?.exponeaProducts;
  const getSegmentationById = (id: string) => state.segmentations.find((segmentation) => id === segmentation.id)?.value;
  const getProductBySku = (sku: string) => state.products.find((product) => sku === product.sku);
  const getProductItemsetBySku = (sku: string) => {
    const itemset = state.itemsets.find((itemset) => sku === itemset.sku);
    if (!itemset) return [];
    if (itemset.products.length > 0) return itemset.products;
    return itemset.exponeaProducts;
  };

  const getUser = async () => {
    state.userLoading = true;

    // https://documentation.bloomreach.com/engagement/reference/customer-properties-test
    await waitUntilExponeaCookieIsSet();
    const userId = app.$cookies.get('__exponea_etc__');
    const customerStore = useCustomerStore();
    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'property',
          property: 'firstname',
        },
        {
          type: 'property',
          property: 'lastname',
        },
        {
          type: 'property',
          property: 'email',
        },
        {
          type: 'property',
          property: 'gender',
        },
        {
          type: 'property',
          property: 'days_till_birthday',
        },
        {
          type: 'property',
          property: 'perfume_favo_drink',
        },
        {
          type: 'property',
          property: 'postcode',
        },
        {
          type: 'property',
          property: 'address',
        },
        {
          type: 'property',
          property: 'style',
        },
        {
          type: 'property',
          property: 'jewellery_preference',
        },
      ],
    };

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email;
    } else {
      body.customer_ids['cookie'] = userId;
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn('[Exponea] No user id found - getUser');
    }

    const { post } = useExponeaApi(app);

    try {
      const results = await post('/customers/attributes', body);
      if (!results) return;
      results.forEach((result, index: number) => {
        const property = body.attributes[index].property;
        const value = result.value;
        state.user[property] = value;
      });
    } catch (error) {
      logger.error(error);
    } finally {
      state.userLoading = false;
    }
  };
  const getRecommendation = async ({ id, productId = null, fillWithRandom, size, force = false }) => {
    // https://documentation.bloomreach.com/engagement/reference/customer-recommendations
    // Use force if you need to do a new call, otherwise it'll use the existing one
    if (!id) return logger.warn('[Exponea] No recommendation ID provided');
    let recommendationFromState: ExponeaRecommendation = state.recommendations.find(
      (recommendation) => recommendation.id === id,
    );
    if (recommendationFromState && !force) return;
    if (!recommendationFromState) {
      state.recommendations.push({
        id,
        exponeaProducts: [],
        products: [],
        loading: true,
      });
      recommendationFromState = state.recommendations.find((recommendation) => recommendation.id === id);
    }

    await waitUntilExponeaCookieIsSet();
    const { post } = useExponeaApi(app);
    const customerStore = useCustomerStore();
    const userId = app.$cookies.get('__exponea_etc__');

    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'recommendation',
          id,
          fillWithRandom,
          size,
          catalogAttributesWhitelist: [
            'title',
            'image',
            'hover_image_url',
            'price',
            'url',
            'reviews_rating_summary',
            'review_count',
            'sku',
            'date_online',
          ],
          items: {},
        },
      ],
    };

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email;
    } else {
      body.customer_ids['cookie'] = userId;
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn(`[Exponea] No user id found - getSegmentation ${id}`);
    }

    if (productId) {
      body.attributes[0].items = { [productId]: 1 };
    }

    try {
      // Get products from Exponea
      const exponeaProducts = await post('/customers/attributes', body);
      if (!exponeaProducts || !exponeaProducts?.[0]?.value?.length) return;
      // Use their SKU's to get their equivalent from Magento
      const skus = exponeaProducts[0]?.value?.map((result) => result.sku);
      if (!skus) return;
      exponeaProducts[0]?.value.forEach((product) =>
        recommendationFromState.exponeaProducts.push({ ...product, __typename: 'ExponeaProduct' }),
      );

      const magentoProducts = await getMagentoProducts(skus);
      recommendationFromState.products = [...magentoProducts];

      // Match order to that of Exponea products
      recommendationFromState.products.sort((a, b) => {
        const aIndex = skus.indexOf(a.sku);
        const bIndex = skus.indexOf(b.sku);
        return aIndex - bIndex;
      });

      if (magentoProducts.length !== skus.length) {
        logger.warn(
          `[Exponea] Recommendations ${id}: Not all Exponea products were found in Magento (${magentoProducts.length} out of ${skus.length})`,
        );
      }
    } catch (error) {
      logger.error(error);
    } finally {
      recommendationFromState.loading = false;
    }
  };
  const getSegmentation = async ({ id }) => {
    // https://documentation.bloomreach.com/engagement/reference/customer-segmentations-test
    if (getSegmentationById(id)) return;
    const { post } = useExponeaApi(app);
    const customerStore = useCustomerStore();
    await waitUntilExponeaCookieIsSet();
    const userId = app.$cookies.get('__exponea_etc__');
    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'segmentation',
          id,
        },
      ],
    };

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email;
    } else {
      body.customer_ids['cookie'] = userId;
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn(`[Exponea] No user id found - getSegmentation ${id}`);
    }

    try {
      const results = await post('/customers/attributes', body);
      if (!results) return;
      state.segmentations.push({
        id,
        value: results[0]?.value,
      });
    } catch (error) {
      logger.error(error);
    }
  };
  const getProduct = async ({ catalogId, sku = null, exponeaId = null }) => {
    // https://documentation.bloomreach.com/engagement/reference/get-catalog-items-2
    // You can use either the SKU or exponeaId to get the product
    const product = getProductBySku(sku);
    if (product) return product;

    const { get } = useExponeaApi(app);
    let url = `/catalogs/${catalogId}/items?limit=1`;
    if (sku) url += `&query=${sku}&field=sku`;
    if (exponeaId) url += `&query=${exponeaId}&field=item_id`;

    try {
      const results = await get(url);
      if (results.data[0]) {
        const result = { ...results.data[0].properties, id: results.data[0].item_id };
        state.products.push(result); // Add to state
        return result; // Return it as well
      }
    } catch (error) {
      logger.error(error);
    }
  };
  const getProductItemset = async (catalogId: string, sku: string) => {
    // Gets itemset from Exponea product, use it to get Exponea products, and with that the Magento products
    // @todo: look for a less intensive option
    if (getProductItemsetBySku(sku).length) return;
    let itemsetFromState = state.itemsets.find((itemset) => itemset.sku === sku);
    if (!itemsetFromState) {
      state.itemsets.push({
        sku,
        exponeaProducts: [],
        products: [],
      });
      itemsetFromState = state.itemsets.find((itemset) => itemset.sku === sku);
    }
    const product = await getProduct({ catalogId, sku });
    const itemsetExponeaIds = product.itemset;
    const exponeaProducts = [];

    // Get Exponea products from itemset ID's
    await Promise.all(
      itemsetExponeaIds.map(async (exponeaId) => {
        const product = await getProduct({ catalogId, exponeaId });
        itemsetFromState.exponeaProducts.push({ ...product, __typename: 'ExponeaProduct' });
      }),
    );

    // Use their SKU's to get their equivalent from Magento
    const exponeaProductSkus = exponeaProducts.map((product) => product.sku);
    const products = await getMagentoProducts(exponeaProductSkus);

    // Match order to that of Exponea products
    products.sort((a, b) => {
      const aIndex = exponeaProductSkus.indexOf(a.sku);
      const bIndex = exponeaProductSkus.indexOf(b.sku);
      return aIndex - bIndex;
    });

    itemsetFromState.products = products;
  };

  async function getMagentoProducts(skus: Array<string>) {
    // Gets Magento products through their SKU
    const searchParams: GetProductSearchParams = {
      pageSize: skus.length,
      filter: {
        sku: {
          in: skus,
        },
      },
    };
    const customQuery = { products: 'products' };
    try {
      const response = await app.$vsf.$magento.api.products(searchParams, customQuery);
      return response?.data?.products?.items;
    } catch (e) {
      logger.error(e);
    }
  }

  const waitUntilExponeaCookieIsSet = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      while (!app.$cookies.get('__exponea_etc__')) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
      }
      resolve();
    });
  };

  return {
    ...toRefs(state),
    getRecommendationById,
    getRecommendationLoadingStateById,
    getExponeaRecommendationById,
    getSegmentationById,
    getProductBySku,
    getProductItemsetBySku,
    getUser,
    getRecommendation,
    getSegmentation,
    getProduct,
    getProductItemset,
  };
});

export default useExponeaStore;
