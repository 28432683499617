import { defineStore } from 'pinia';
import { reactive, toRefs } from '@nuxtjs/composition-api';
import { PageModel } from '@bloomreach/spa-sdk';

export interface HrefLang {
  rel: string;
  language: string;
  url: string;
}

export const usePageStore = defineStore('page', () => {
  const state = reactive({
    hrefLangs: <HrefLang[]>[],
    routeData: <Record<string, any>>{
      type: '',
    },
    totalProducts: <number>0,
    loaded: <boolean>false,
    pageLayout: 'one-column',
    pageType: '',
    pageData: <Record<string, any>>{},
    quickFilters: [],
    configuration: {},
    bloomreachPages: <Record<string, PageModel>>{},
    previousPage: <Record<string, any>>{},
  });


  return {
    ...toRefs(state),
  };
});

export default usePageStore;
