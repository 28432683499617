import gql from 'graphql-tag';
import wishlistFields from './wishlistFragment.gql';
/**
 * addWishlistItemsToCart
 */
export default gql`
  mutation addWishlistItemsToCart($wishlistId: ID!, $wishlistItemIds: [ID!]) {
    addWishlistItemsToCart(wishlistId: $wishlistId, wishlistItemIds: $wishlistItemIds) {
      status
      add_wishlist_items_to_cart_user_errors {
        code
        message
      }
      wishlist {
        id
        items_v2(currentPage: 1, pageSize: 10) {
          ...wishlistFields
        }
      }
    }
  }
  ${wishlistFields}
`;
