import { Context } from '@vue-storefront/core';
import { Cart } from '@vue-storefront/magento';
import customerCartCompleteGql from '~/customQueries/queries/customerCartComplete';
import logger from '~/utilities/logger';

export const loadCartCommand = {
  execute: async (
    context: Context,
    params: { customQuery: object; realCart: boolean; completeCart: boolean; app: any } = {
      customQuery: {},
      realCart: false,
      completeCart: false,
      app: {},
    },
  ) => {
    const apiState = context.$magento.config.state;
    logger.debug('[Magento Storefront]: Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const createVirtualCart = () => null as Cart;

    /**
     * Synchronizes the Storefront cart with the Magento cart.
     *
     * @param {string} cartId - The ID of the cart to synchronize.
     * @returns {Promise<void>} A Promise that resolves when the synchronization is complete.
     * @throws Will throw an error if the response from the Magento backend is not OK (status code is not 200-299).
     */
    const syncVueWithMagento = async (cartId: string): Promise<void> => {
      const url = `${params.app?.$config?.storeUrl}/vue/cart/sync/token/${
        customerToken !== undefined ? customerToken : ''
      }/cart/${cartId}`;

      logger.debug('[Magento Sync] URL: ', url);

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`[Magento Sync] HTTP error! status: ${response.status}`);
        }
        await response.json();
        logger.debug('[Magento Sync] Done');
      } catch (error) {
        logger.error('[Magento Sync] Error:', error);
      }
    };

    const createRealCart = async (): Promise<string> => {
      logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      logger.debug('[Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      await syncVueWithMagento(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const realId = id.indexOf('|') ? id.split('|').pop() : id;

      const { data, errors } = await context.$magento.api.cart(realId);
      logger.debug('[Result]:', { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        // If completeCart is requested do custom query
        const { data, errors } = params.completeCart
          ? await context.$magento.api.customQuery({
              query: customerCartCompleteGql,
            })
          : await context.$magento.api.customerCart();

        logger.debug('[Result]:', { data, errors });

        if (!data?.customerCart && errors?.length) {
          throw errors[0];
        }

        apiState.setCartId(data.customerCart.id);
        data.customerCart.items = data.customerCart.items.filter(Boolean);

        return data.customerCart as Cart;
      } catch {
        apiState.setCustomerToken();
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch {
      apiState.setCartId();
      return await getCart(virtual);
    }
  },
};
