import { useContext } from '@nuxtjs/composition-api';

// Translations for the category page names are handled in ProductRecommendations.vue
// If a recommendation for a new category page is required, add the translations there

const exponeaEnvironmentConstants = {
  'nl-nl': {
    FOOTER_SUBSCRIPTION_SEGMENTATION_ID: '5ff32b92083710d2a4c16b35',
    EMPTY_CART_RECOMMENDATIONS_ID: '5f855c52195c42c769e7dab8',
    PRODUCT_PAGE_RECOMMENDATIONS_CATALOG: '652e428fb458e41f7bd4f3fd',
    PRODUCT_PAGE_RECOMMENDATIONS_ID: '5f8d9417daf48fd49f53be15',
    PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID: '5eb94ad6265d65a5081855e1',
    PRODUCT_PAGE_BESTSELLERS_ID: '5f855c52195c42c769e7dab8',
    SIGNUP_SOURCE_SOLD_OUT_REGISTER: 'coming_back_register',
    SIGNUP_SOURCE_COMING_SOON_REGISTER: 'coming_back_register',
    CONSENT_ID: '60a3c18d60901ae0046e87b4',
    CUSTOMER_TYPE_ID: '60a3c179b07067f8f72e7249',
    CATEGORY_PAGE_ACCESSOIRES_DEFAULT_ID: '64621567c30efa2fa6286c54',
    CATEGORY_PAGE_ACCESSOIRES_KNOWN_ID: '5f3cf823f6d2a9108fe5e3d9',
    CATEGORY_PAGE_ACCESSOIRES_MEN_ID: '600fcf5fb3ad0c790c2fcff7',
    CATEGORY_PAGE_CLOTHES_DEFAULT_ID: '645e456422797a1e2432c299',
    CATEGORY_PAGE_CLOTHES_KNOWN_ID: '5f059ff9a7895e48bbe22578',
    CATEGORY_PAGE_CLOTHES_MEN_ID: '5f059ff9a7895e48bbe22578',
    CATEGORY_PAGE_DEFAULT_ID: '61dc549958355d0f78d71d28',
    CATEGORY_PAGE_JEWELLERY_DEFAULT_ID: '6461f78300fb0ad9b5946d69',
    CATEGORY_PAGE_JEWELLERY_KNOWN_ID: '5f059f7d83ebcdb1330f86ae',
    CATEGORY_PAGE_JEWELLERY_MEN_ID: '600fc780be5856df3a1e9b01',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_DEFAULT_ID: '6462162216eac33323df721d',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_KNOWN_ID: '5f44b7ae2fe9b69eea8b9d34',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_MEN_ID: '600fc8b24f64990c0ec135ac',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_DEFAULT_ID: '6462163ec8bcea3f94418f27',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_KNOWN_ID: '5f44b784a0578d69bfe1fb33',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_MEN_ID: '600fc850b59fb371d93d3d30',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_DEFAULT_ID: '64621606d61afda369c963a0',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_KNOWN_ID: '5f44b7c15325a6bc40130848',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_MEN_ID: '600fcdbf91194f190d49054c',
    CATEGORY_PAGE_JEWELLERY_RINGS_DEFAULT_ID: '646215c7c8bcea3f94418f1b',
    CATEGORY_PAGE_JEWELLERY_RINGS_KNOWN_ID: '5f44b7e19ac12713411369c2',
    CATEGORY_PAGE_JEWELLERY_RINGS_MEN_ID: '600fce46b3d31066d895ea19',
    CATEGORY_PAGE_NEW_DEFAULT_ID: '646214ed0385fb760315c543',
    CATEGORY_PAGE_NEW_KNOWN_ID: '5f3cf71158b322ee800badaa',
    CATEGORY_PAGE_NEW_MEN_ID: '600fcfd0d4423703c7237968',
    CATEGORY_PAGE_SALE_DEFAULT_ID: '6462159c1e2e7fe225c41f3e',
    CATEGORY_PAGE_SALE_KNOWN_ID: '6215f9433eb10b0a41e9b339',
    CATEGORY_PAGE_SALE_MEN_ID: '6215f9433eb10b0a41e9b339',
    REVIEW_OR_COMPANY_FEEDBACK_ID: '668baee75fb5ce2881414149',
  },
  de: {
    FOOTER_SUBSCRIPTION_SEGMENTATION_ID: '60a7a3813665b80e4f3a8a87',
    EMPTY_CART_RECOMMENDATIONS_ID: '60a7a3813665b80e4f3a8a7d',
    PRODUCT_PAGE_RECOMMENDATIONS_CATALOG: '65377d14dea8f843d18934b6',
    PRODUCT_PAGE_RECOMMENDATIONS_ID: '60acb5f9130410c5eb210182',
    PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID: '60acb7f2b2c2e30bc6867182',
    PRODUCT_PAGE_BESTSELLERS_ID: '60a7a3813665b80e4f3a8a7d',
    SIGNUP_SOURCE_SOLD_OUT_REGISTER: 'coming_back_register',
    SIGNUP_SOURCE_COMING_SOON_REGISTER: 'coming_back_register',
    CONSENT_ID: '60a3c18d60901ae0046e87b4',
    CUSTOMER_TYPE_ID: '60a3c179b07067f8f72e7249',
    CATEGORY_PAGE_ACCESSOIRES_DEFAULT_ID: '646b2963ed163f94f469f3fb',
    CATEGORY_PAGE_ACCESSOIRES_KNOWN_ID: '61a62168f091619dece27f51',
    CATEGORY_PAGE_ACCESSOIRES_MEN_ID: '61a88fb436030ae0a00da91d',
    CATEGORY_PAGE_CLOTHES_DEFAULT_ID: '646b29af81a525851bfd7eb5',
    CATEGORY_PAGE_CLOTHES_KNOWN_ID: '61a6242de2c8b243adebf801',
    CATEGORY_PAGE_CLOTHES_MEN_ID: '61a6242de2c8b243adebf801',
    CATEGORY_PAGE_DEFAULT_ID: '640f135550a7d59231865b6f',
    CATEGORY_PAGE_JEWELLERY_DEFAULT_ID: '646b2a313902c5903cdb1eb6',
    CATEGORY_PAGE_JEWELLERY_KNOWN_ID: '61a62705cd9f14703cd458d8',
    CATEGORY_PAGE_JEWELLERY_MEN_ID: '61a8915b36030ae0a00da96f',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_DEFAULT_ID: '646b298407007ea0ee946963',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_KNOWN_ID: '61a62185785f494c9d2881a3',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_MEN_ID: '61a88fcc8168317aa7cf3dd0',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_DEFAULT_ID: '646b29e0af030e4a28c00589',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_KNOWN_ID: '61a625cc785f494c9d2881c0',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_MEN_ID: '61a89122a065f833e9a44c76',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_DEFAULT_ID: '646b2999f1b7cd2a364b3ebf',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_KNOWN_ID: '61a6236270334b1b63cfc822',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_MEN_ID: '61a8900bbadffe16f07da766',
    CATEGORY_PAGE_JEWELLERY_RINGS_DEFAULT_ID: '646b2a00733b72f7269fde0b',
    CATEGORY_PAGE_JEWELLERY_RINGS_KNOWN_ID: '61a6268826993039d145e5a6',
    CATEGORY_PAGE_JEWELLERY_RINGS_MEN_ID: '61a8913b9c77eec2321726e4',
    CATEGORY_PAGE_NEW_DEFAULT_ID: '646b29c85420fc21f2abbe7d',
    CATEGORY_PAGE_NEW_KNOWN_ID: '61a739effa250911a6782cf3',
    CATEGORY_PAGE_NEW_MEN_ID: '61a8905653acdc2511d02b77',
    CATEGORY_PAGE_SALE_DEFAULT_ID: '646b2a19de3b9fc7c73f31f4',
    CATEGORY_PAGE_SALE_KNOWN_ID: '6215fbe39d447ac3addff5aa',
    CATEGORY_PAGE_SALE_MEN_ID: '6215fbe39d447ac3addff5aa',
  },
  en: {
    FOOTER_SUBSCRIPTION_SEGMENTATION_ID: '607d31e3882f6498367b6390',
    EMPTY_CART_RECOMMENDATIONS_ID: '60815a32d9388be05313d4f8',
    PRODUCT_PAGE_RECOMMENDATIONS_CATALOG: '6523ca2c98df4994b339b5c6',
    PRODUCT_PAGE_RECOMMENDATIONS_ID: '60815ed55e3fec35114f322f',
    PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID: '6080228820255d44c3d30f40',
    PRODUCT_PAGE_BESTSELLERS_ID: '60815a848bd199ad101e9b45',
    SIGNUP_SOURCE_SOLD_OUT_REGISTER: 'coming_back_register',
    SIGNUP_SOURCE_COMING_SOON_REGISTER: 'coming_back_register',
    CONSENT_ID: '60a3c18d60901ae0046e87b4',
    CUSTOMER_TYPE_ID: '60a3c179b07067f8f72e7249',
    CATEGORY_PAGE_ACCESSOIRES_DEFAULT_ID: '60812bb89c613c981c6b240f',
    CATEGORY_PAGE_ACCESSOIRES_KNOWN_ID: '608121ceef38138374dec103',
    CATEGORY_PAGE_ACCESSOIRES_MEN_ID: '61a88fb4211f8e114a2fc4cc',
    CATEGORY_PAGE_CLOTHES_DEFAULT_ID: '646b29af243397c2fd4fcb50',
    CATEGORY_PAGE_CLOTHES_KNOWN_ID: '608146aa5f9215b833495274',
    CATEGORY_PAGE_CLOTHES_MEN_ID: '608146aa5f9215b833495274',
    CATEGORY_PAGE_DEFAULT_ID: '64141c085dcadafd809c5471',
    CATEGORY_PAGE_JEWELLERY_DEFAULT_ID: '6081596abb4cfd1b7538bb0e',
    CATEGORY_PAGE_JEWELLERY_KNOWN_ID: '60815907521ffb4273cac658',
    CATEGORY_PAGE_JEWELLERY_MEN_ID: '61a8915bfb3e22634dda6808',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_DEFAULT_ID: '646b29845420fc21f2abbe70',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_KNOWN_ID: '60812c05795aaa19d72f3a4f',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_MEN_ID: '61a88fcc66768167b43ef98f',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_DEFAULT_ID: '646b29e007007ea0ee946977',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_KNOWN_ID: '60814983b54c8bea0dfeea72',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_MEN_ID: '61a89122a065f833e9a44c79',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_DEFAULT_ID: '646b29993902c5903cdb1e9d',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_KNOWN_ID: '608144b228cb78cfd137d03e',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_MEN_ID: '61a8900bef8048d02d376732',
    CATEGORY_PAGE_JEWELLERY_RINGS_DEFAULT_ID: '646b2a013902c5903cdb1ea7',
    CATEGORY_PAGE_JEWELLERY_RINGS_KNOWN_ID: '60815665fe35ceb61d190d0f',
    CATEGORY_PAGE_JEWELLERY_RINGS_MEN_ID: '61a8913bcaace08dd221deb8',
    CATEGORY_PAGE_NEW_DEFAULT_ID: '646b29c807007ea0ee94696d',
    CATEGORY_PAGE_NEW_KNOWN_ID: '61a739eea9b8bb44baac1ecb',
    CATEGORY_PAGE_NEW_MEN_ID: '61a8905653acdc2511d02b74',
    CATEGORY_PAGE_SALE_DEFAULT_ID: '646b2a195ea3f9d95aa1f84d',
    CATEGORY_PAGE_SALE_KNOWN_ID: '6215fbe34a7fdc2f2605bad1',
    CATEGORY_PAGE_SALE_MEN_ID: '6215fbe34a7fdc2f2605bad1',
  },
  fr: {
    FOOTER_SUBSCRIPTION_SEGMENTATION_ID: '626fc37530a430249e53b220',
    EMPTY_CART_RECOMMENDATIONS_ID: '626ba9fa18a6feaac1dc208b',
    PRODUCT_PAGE_RECOMMENDATIONS_CATALOG: '652e5f4157996127b2592cfc',
    PRODUCT_PAGE_RECOMMENDATIONS_ID: '626bf172216e838b38438e72',
    PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID: '626aa6289d2b3a2e6661efb1',
    PRODUCT_PAGE_BESTSELLERS_ID: '626ba9fa18a6feaac1dc208b',
    SIGNUP_SOURCE_SOLD_OUT_REGISTER: 'coming_back_register',
    SIGNUP_SOURCE_COMING_SOON_REGISTER: 'coming_back_register',
    CONSENT_ID: '60a3c18d60901ae0046e87b4',
    CUSTOMER_TYPE_ID: '60a3c179b07067f8f72e7249',
    CATEGORY_PAGE_ACCESSOIRES_DEFAULT_ID: '646b2963a2d075993663c9f5',
    CATEGORY_PAGE_ACCESSOIRES_KNOWN_ID: '626a9bde0f0b33febd0e22b1',
    CATEGORY_PAGE_ACCESSOIRES_MEN_ID: '626a9c3173ffaed629d43271',
    CATEGORY_PAGE_CLOTHES_DEFAULT_ID: '646b29af592040f2a8bbc8b0',
    CATEGORY_PAGE_CLOTHES_KNOWN_ID: '626a9d423e1bfa8343bb3e10',
    CATEGORY_PAGE_CLOTHES_MEN_ID: '626a9d423e1bfa8343bb3e10',
    CATEGORY_PAGE_DEFAULT_ID: '641438fcfa1da94caa013ef9',
    CATEGORY_PAGE_JEWELLERY_DEFAULT_ID: '646b2a31ae5085ba9dc8e2c4',
    CATEGORY_PAGE_JEWELLERY_KNOWN_ID: '626aa0a4f4727a25160b8d61',
    CATEGORY_PAGE_JEWELLERY_MEN_ID: '626aa0c702813bfd46b7918b',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_DEFAULT_ID: '646b2984ef25c99ba21d9a4f',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_KNOWN_ID: '626a9c4320f757e85d7aaa2a',
    CATEGORY_PAGE_JEWELLERY_BRACELETS_MEN_ID: '626a9c8f86c63efa1a9a5602',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_DEFAULT_ID: '646b29e0a91f88cf523e1dc3',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_KNOWN_ID: '626a9d54cd8021101facc228',
    CATEGORY_PAGE_JEWELLERY_EARRINGS_MEN_ID: '626a9d737c87607a30272a8f',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_DEFAULT_ID: '646b2999a91f88cf523e1db9',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_KNOWN_ID: '626aa150befd6d97334ab0c9',
    CATEGORY_PAGE_JEWELLERY_NECKLACES_MEN_ID: '626aa17f4ca366fcdb8012e6',
    CATEGORY_PAGE_JEWELLERY_RINGS_DEFAULT_ID: '646b2a01592040f2a8bbc8ba',
    CATEGORY_PAGE_JEWELLERY_RINGS_KNOWN_ID: '626aa40c8ce1f11707ff1cb4',
    CATEGORY_PAGE_JEWELLERY_RINGS_MEN_ID: '626aa42b1850b1b0c4d65f03',
    CATEGORY_PAGE_NEW_DEFAULT_ID: '646b29c85b79d885d950c62f',
    CATEGORY_PAGE_NEW_KNOWN_ID: '626aa28908fd4921d14283d4',
    CATEGORY_PAGE_NEW_MEN_ID: '626aa2793c5c489141fdac13',
    CATEGORY_PAGE_SALE_DEFAULT_ID: '646b2a182058a864739a6dc3',
    CATEGORY_PAGE_SALE_KNOWN_ID: '626aa7153e073ca6cc3ab284',
    CATEGORY_PAGE_SALE_MEN_ID: '626aa7153e073ca6cc3ab284',
  },
};

const useExponeaConstants = () => {
  const { app } = useContext();
  const environment = app.i18n.locale || 'de';
  return exponeaEnvironmentConstants[environment];
};

export default useExponeaConstants;
