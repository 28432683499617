import getCustomAttributeMetadata from '~/customQueries/queries/getCustomAttributeMetadata';
import logger from '~/utilities/logger';
export const getAttributeData = async (context) => {
  try {
    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: getCustomAttributeMetadata,
      queryVariables: null,
    });

    logger.debug(`useCustomAttributeMetadata/search/result`, data);
    return data?.customAttributeMetadata?.items;
  } catch (err) {
    logger.error(`useCustomAttributeMetadata/search/error`, err);
    throw err;
  }
};
