/**
 * Returns the filters from the 'pretty url' route path.
 */

import { useBloomreachDiscoveryStore, usePageStore } from '~/stores';
import { facetIds } from '~/stores/useBloomreachDiscoveryStore';
import { storeToRefs } from 'pinia';
import logger from '~/utilities/logger';

export default (context) => {
  const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
  const { prettyUrls, selectedPrettyUrls, selectedFilters, appliedFilters, selectedSortOption } =
    storeToRefs(bloomreachDiscoveryStore);
  const { routeData } = storeToRefs(usePageStore());
  const route = context.route.value;

  delete routeData.value?.query?.q; // We don't need this in the filters

  selectedPrettyUrls.value = routeData.value?.facets || [];

  let pathFilters = {};

  if (routeData.value?.facets) {
    pathFilters = routeData.value?.facets?.reduce((prev, curr) => {
      const facet = prettyUrls.value?.find((f) => {
        return (
          encodeURI(f.slug?.toLowerCase()) === encodeURI(curr.toLowerCase()) ||
          encodeURI(f.slug?.toLowerCase()) === curr.toLowerCase() ||
          f.slug?.toLowerCase() === decodeURIComponent(curr.toLowerCase())
        );
      });

      const { label, type } = facet ?? {};
      if (!label || !type) {
        // invalid pretty filter therefore page doesn't exist
        if (!routeData.value.relative_url.includes(curr)) {
          logger.warn(`Ignoring invalid pretty filter in URL (${curr}).`);
        }
        return prev;
      }

      const mappedType = bloomreachDiscoveryStore.facetsKeysMap[type] ?? type;

      return {
        ...prev,
        [mappedType]: Array.isArray(prev[mappedType]) ? [...prev[mappedType], label] : [label],
      };
    }, {});
  }

  logger.info('[getFiltersFromRoutePath] pathFilters: ' + JSON.stringify(pathFilters).replace(/[\\"\[\]]/g, ''));

  let queryFilters: { [k: string]: any } = [];

  const query = process.server ? route.query : Object.fromEntries(new URLSearchParams(window.location.search));

  if (query && Object.keys(query).length > 0) {
    const queryParams = { ...query, q: undefined };
    queryFilters = Object.fromEntries(
      Object.entries(queryParams)
        .filter((query) => query[0] !== 'p' && facetIds.includes(query[0]))
        .map(([key, value]) => {
          let newVal: any = Array.isArray(value?.toString().split(',')) && key ? value.toString().split(',') : [value];
          if (key === 'price') newVal = value;
          return [key, newVal];
        }),
    );
  }

  logger.info('[getFiltersFromRoutePath] queryFilters: ' + JSON.stringify(queryFilters).replace(/[\\"\[\]]/g, ''));

  const result = { ...pathFilters, ...queryFilters };
  const currentSort = query?.sort;

  selectedFilters.value = result;
  appliedFilters.value = result;
  selectedSortOption.value = Array.isArray(currentSort) ? currentSort[0] : currentSort || '';
};
