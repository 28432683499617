import { defineStore } from 'pinia';
import { Cart } from '@vue-storefront/magento';
import { Ref, computed, ComputedRef, reactive, toRefs } from '@nuxtjs/composition-api';
import { useCustomerStore } from '~/stores';
import _merge from 'lodash.merge';

interface CartState {
  cart: Ref<Cart>;
  cartLoading: Ref<boolean>;
  shipping: Ref;
  setCart: Function;
  clearCart: Function;
  stepsCompleted: any;
  products: any;
  hasDiscounts: any;
  selectedShippingMethod: any;
  getDiscounts: any;
  availableShippingMethods: ComputedRef<any[]>;
  selectedShippingAddress: any;
  payment: any;
  paymentMethods: any;
  paymentMethodsAdyen: any;
  hasShippingAddress: ComputedRef<boolean>;
  selectedBillingAddress: any;
  hasBillingAddress: ComputedRef<boolean>;
  selectedShippingAddressId: ComputedRef<string>;
  selectedBillingAddressId: ComputedRef<string>;
  showAtcModal: Ref<boolean>;
}

export const useCartStore = defineStore('cart', (): CartState => {
  const state = reactive({
    cart: {
      id: null,
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: [
        {
          id: null,
          available_shipping_methods: [],
          city: '',
          company: null,
          country: {
            code: '',
            label: '',
          },
          customer_notes: null,
          firstname: '',
          lastname: '',
          pickup_location_code: null,
          postcode: null,
          region: null,
          selected_shipping_method: null,
          street: [''],
          telephone: '',
        },
      ],
      items: [],
      applied_coupons: null,
      billing_address: {
        id: '',
        city: '',
        company: null,
        country: {
          code: '',
          label: '',
        },
        firstname: '',
        lastname: '',
        postcode: null,
        region: null,
        street: [''],
        telephone: '',
      },
      prices: {
        subtotal_excluding_tax: null,
        subtotal_including_tax: null,
        grand_total: null,
        discounts: [],
      },
    },

    cartLoading: true,

    payment: {
      available_payment_methods: [],
      adyen_payment_methods: [],
    },

    shipping: {
      shipping_options: [],
      pickup_locations: [],
      selected_shipping_method: null,
      token: '',
    },
    showAtcModal: false,
  });

  const setCart = (cartData) => {
    if (!cartData) return;

    // Ensure reactivity on shipping_addresses
    if (!cartData.shipping_addresses) {
      cartData.shipping_addresses = [];
    }

    const assignData = _merge(state.cart, cartData);

    // Don't persist these state.carts from _merge, but use the exact value from cartData
    assignData.items = cartData.items;
    assignData.applied_coupons = cartData.applied_coupons;
    assignData.prices.discounts = cartData.prices?.discounts;

    state.cart = assignData;
  };

  const clearCart = () => {
    state.cart = {
      id: null,
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: [],
      items: [],
      applied_coupons: null,
      billing_address: null,
      prices: {
        subtotal_excluding_tax: null,
        subtotal_including_tax: null,
        grand_total: null,
        discounts: null,
      },
    };
  };

  const calculateDiscounts = (discounts) =>
    discounts.reduce((a, b) => Number.parseFloat(`${a}`) + Number.parseFloat(`${b.amount.value}`), 0);

  // Getters
  const products = computed(() => state.cart.items);
  const discount = computed(() => calculateDiscounts(state.cart.prices?.discounts ?? []));
  const hasDiscounts = computed(() => Math.abs(discount.value) > 0);
  const availableShippingMethods = computed(() => state.cart?.shipping_addresses[0]?.available_shipping_methods);
  const selectedShippingMethod = computed(() =>
    state.cart?.shipping_addresses?.length > 0 ? state.cart?.shipping_addresses[0]?.selected_shipping_method : null,
  );

  const selectedShippingAddress = computed(() => {
    const address = state.cart?.shipping_addresses[0];
    if (!address) return null;
    if (!address?.id) {
      const customerStore = useCustomerStore();
      const selectedAddress = customerStore.user?.addresses?.find((a) => {
        return (
          a.city === address.city &&
          a.firstname === address.firstname &&
          a.lastname === address.lastname &&
          a.postcode === address.postcode &&
          a.country_code === address.country.code &&
          a.region.region_code === address.region.code &&
          a.street.every((element, index) => element === address.street[index])
        );
      });
      if (selectedAddress?.id) {
        address.id = selectedAddress?.id;
      }
    }
    return address;
  });
  const selectedShippingAddressId: ComputedRef<string> = computed(
    () => selectedShippingAddress.value?.id?.toString() || '',
  );
  const hasShippingAddress = computed(() => {
    const {
      city,
      firstname,
      lastname,
      postcode,
      country,
      street: [firstStreet = ''] = [],
    } = selectedShippingAddress.value;
    return !!city && !!firstname && !!lastname && !!postcode && !!country.code && !!firstStreet;
  });

  const selectedBillingAddress = computed(() => {
    const address = state.cart?.billing_address;
    if (!address) return null;
    if (!address?.id) {
      const customerStore = useCustomerStore();
      const selectedAddress = customerStore.user?.addresses?.find((a) => {
        return (
          a.city === address.city &&
          a.firstname === address.firstname &&
          a.lastname === address.lastname &&
          a.postcode === address.postcode &&
          a.country_code === address.country.code &&
          a.region.region_code === address.region.code &&
          a.street.every((element, index) => element === address.street[index])
        );
      });
      if (selectedAddress?.id) {
        address.id = selectedAddress?.id?.toString();
      }
    }
    return address;
  });
  const hasBillingAddress = computed(() => {
    if (!selectedBillingAddress.value) return false;
    const {
      city,
      firstname,
      lastname,
      postcode,
      country,
      street: [firstStreet = ''] = [],
    } = selectedBillingAddress.value;
    return !!city && !!firstname && !!lastname && !!postcode && !!country.code && !!firstStreet;
  });
  const selectedBillingAddressId: ComputedRef<string> = computed(() => {
    if (selectedBillingAddress.value?.id) return selectedBillingAddress.value.id.toString();
    const address = state.cart.shipping_addresses.find(
      (address) =>
        address.postcode === state.cart.billing_address?.postcode &&
        address.street.every((element, index) => element === state.cart.billing_address?.street[index]),
    );
    return address?.id?.toString() || '';
  });

  const getDiscounts = computed(() => {
    return Array.isArray(state.cart.prices?.discounts)
      ? state.cart.prices.discounts.map((d) => ({
          id: d.label,
          name: d.label,
          description: '',
          value: d.amount.value,
          code: d.label,
        }))
      : [];
  });
  const paymentMethods = computed(() => state.payment.available_payment_methods);
  const paymentMethodsAdyen = computed(() => state.payment.adyen_payment_methods);

  const stepsCompleted = computed(() => {
    const shipping_address = !!state.cart.shipping_addresses.length;
    const billing_address = !!state.cart.billing_address?.postcode;
    const selected_shipping_method = !!selectedShippingMethod.value;
    const step1 = [shipping_address, billing_address, selected_shipping_method].every(Boolean);

    const step2 = {
      shipping_address: state.cart.shipping_addresses.length,
      billing_address: state.cart.billing_address,
    };

    return {
      shipping_address,
      billing_address,
      selected_shipping_method,
      step1,
    };
  });

  return {
    ...toRefs(state),
    setCart,
    clearCart,
    stepsCompleted,
    products,
    hasDiscounts,
    selectedShippingMethod,
    getDiscounts,
    availableShippingMethods,
    selectedShippingAddress,
    paymentMethods,
    paymentMethodsAdyen,
    hasShippingAddress,
    selectedBillingAddress,
    hasBillingAddress,
    selectedShippingAddressId,
    selectedBillingAddressId,
  };
});

export default useCartStore;
