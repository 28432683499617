// Components
import Accordion from '~/components/MyJewellery/Accordion.vue';
import ArticleFeed from '~/components/MyJewellery/ArticleFeed.vue';
import Banner from '~/components/MyJewellery/Banner.vue';
import BlogPostHeader from '~/components/MyJewellery/BlogPostHeader.vue';
import Cards from '~/components/MyJewellery/Cards.vue';
import Fallback from '~/components/MyJewellery/Fallback.vue';
import HeaderNotification from '~/components/MyJewellery/HeaderNotification.vue';
import Identifier from '~/components/MyJewellery/Identifier.vue';
import InlineNotification from '~/components/MyJewellery/InlineNotification.vue';
import InlineContainer from '~/components/MyJewellery/InlineContainer.vue';
import Photoslurp from '~/components/MyJewellery/Photoslurp.vue';
import PiercingStoreGrid from '~/components/MyJewellery/PiercingStores/Grid.vue';
import PiercingBooking from '~/components/MyJewellery/PiercingStores/PiercingBooking.vue';
import RichText from '~/components/MyJewellery/RichText.vue';
import Row from '~/components/MyJewellery/Row.vue';
import SeoText from '~/components/MyJewellery/SeoText.vue';
import ShopInShop from '~/components/MyJewellery/ShopInShop.vue';
import StoreGrid from '~/components/MyJewellery/StoreGrid.vue';
import TopNavMenu from '~/components/TopNav/TopNavMenu.vue';
import Video from '~/components/MyJewellery/Video.vue';
import ProductCarousel from '~/components/MyJewellery/ProductCarousel.vue';
import IndividualProductCard from '~/components/MyJewellery/IndividualProductCard.vue';
import Instagram from '~/components/MyJewellery/Instagram.vue';
import ExponeaForm from '~/components/MyJewellery/ExponeaForm.vue';
import LogoBanner from '~/components/MyJewellery/LogoBanner.vue';
import CategoryHeader from '~/components/MyJewellery/CategoryHeader.vue';
import TikTok from '~/components/MyJewellery/TikTok.vue';

// Footer
import CtaBoutique from '~/components/MyJewellery/Footer/CtaBoutique.vue';
import CtaChat from '~/components/MyJewellery/Footer/CtaChat.vue';
import CtaReviews from '~/components/MyJewellery/Footer/CtaReviews.vue';
import CtaSubscription from '~/components/MyJewellery/Footer/CtaSubscription.vue';

import { TYPE_CONTAINER_INLINE, TYPE_CONTAINER_ITEM_UNDEFINED, TYPE_CONTAINER_NO_MARKUP } from '@bloomreach/spa-sdk';

export default function useBloomreachComponents() {
  const mapping = {
    menu: TopNavMenu,
    mjArticleFeed: ArticleFeed,
    mjBanner: Banner,
    mjBlogPostHeader: BlogPostHeader,
    mjFaq: Accordion,
    mjFooterCTABoutique: CtaBoutique,
    mjFooterCtaChat: CtaChat,
    mjFooterCTAReviews: CtaReviews,
    mjFooterCtaSubscription: CtaSubscription,
    mjHeaderNotification: HeaderNotification,
    mjIdentifier: Identifier,
    mjImageTextCard: Cards,
    mjInlineNotification: InlineNotification,
    mjPhotoslurp: Photoslurp,
    mjPiercingGrid: PiercingStoreGrid,
    mjPiercingBooking: PiercingBooking,
    mjRichText: RichText,
    mjRow: Row,
    mjSEO: SeoText,
    mjShopInShopGrid: ShopInShop,
    mjStoreGrid: StoreGrid,
    mjVideo: Video,
    mjProductCarousel: ProductCarousel,
    mjProduct: IndividualProductCard,
    mjInstagram: Instagram,
    mjForm: ExponeaForm,
    mjLogoBanner: LogoBanner,
    mjCategoryHeader: CategoryHeader,
    mjTiktok: TikTok,
    [TYPE_CONTAINER_INLINE]: InlineContainer,
    [TYPE_CONTAINER_ITEM_UNDEFINED]: Fallback,
    [TYPE_CONTAINER_NO_MARKUP]: InlineContainer,
  };

  return { mapping };
}
