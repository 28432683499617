import { Context } from '@nuxt/types';
import { storeConfigGetters, StoreConfig } from '@vue-storefront/magento';
import cookieNames from '~/enums/cookieNameEnum';

export default async ({ app, route, req }: Context) => {
  let config = null;

  const selectedLocale =
    route?.path?.substring(1)?.split('/')?.shift().substring(0, 2) ?? app.$cookies.get(cookieNames.localeCookieName);

  if (config === null) {
    const storeConfig: StoreConfig[] = require('~/composables/useConfig/storeConfig.json');
    const selectedStore = storeConfig.find((store) => store.locale === selectedLocale);
    config = selectedStore || storeConfig[0];
  }

  const cookieOptions = {
    path: '/',
    maxAge: 60 * 60 * 24 * 7,
  };
  const cookieList = [
    { name: cookieNames.storeCookieName, value: storeConfigGetters.getCode(config), cookieOptions },
    { name: cookieNames.localeCookieName, value: storeConfigGetters.getLocale(config), cookieOptions },
    { name: cookieNames.currencyCookieName, value: storeConfigGetters.getCurrency(config), cookieOptions },
    { name: cookieNames.countryCookieName, value: storeConfigGetters.getLocale(config).slice(0, 2), cookieOptions },
  ];
  app.$cookies.setAll(cookieList);
  app.i18n.setLocale(storeConfigGetters.getLocale(config));

  app.$vsf.$magento.config.store = storeConfigGetters.getCode(config);
  app.$vsf.$magento.config.locale = storeConfigGetters.getCode(config);
  app.$vsf.$magento.config.country = storeConfigGetters.getLocale(config).slice(0, 2);
  app.$vsf.$magento.config.currency = storeConfigGetters.getCurrency(config);

  if (req) {
    // Set the cookies manualy for the Magento API. They won't be added to the request otherwise.
    const cookies = app.$cookies.getAll();
    cookies[cookieNames.storeCookieName] = storeConfigGetters.getCode(config);
    cookies[cookieNames.localeCookieName] = storeConfigGetters.getLocale(config);
    cookies[cookieNames.currencyCookieName] = storeConfigGetters.getCurrency(config);
    cookies[cookieNames.countryCookieName] = storeConfigGetters.getLocale(config).slice(0, 2);

    const cookieString = Object.entries(cookies)
      .map(([key, value]) => `${key}=${value}`)
      .join('; ');

    app.$vsf.$magento.client.defaults.headers.cookie = cookieString;
  }
};
