import { datadogRum } from '@datadog/browser-rum';

const BOT_USERAGENT_REGEX =
  /(Googlebot|Applebot|bingbot|Storebot|BrightEdge Crawler|IVW-Crawler|YandexRenderResourcesBot|Bytespider|SeekportBot|YandexMobileBot|AdsBot-Google|AppEngine-Google|BingPreview|Baiduspider-render|FacebookBot)/;

export default ({
  $config: {
    datadog: { env, service, sessionReplaySampleRate, sessionSampleRate },
  },
}) => {
  if (!env || !service || !sessionReplaySampleRate || !sessionSampleRate) return;

  const isBot = BOT_USERAGENT_REGEX.test(navigator.userAgent);
  const conditionalSampleRate = isBot ? 0 : Number(sessionSampleRate);

  datadogRum.init({
    applicationId: '50a97c54-c95d-4ce8-9489-32cee2cb0aa0',
    clientToken: 'pub1b4b60ac2fe1d23e061216322d99700c',
    site: 'datadoghq.eu',
    service,
    env,
    sessionSampleRate: conditionalSampleRate,
    sessionReplaySampleRate: Number(sessionReplaySampleRate),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};
