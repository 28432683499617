import logger from '~/utilities/logger';
import { SetGuestEmailOnCartInput } from '@vue-storefront/magento-api';
import { AttachToCartParams } from '~/composables/useGuestUser/useGuestUser';
import { ComposableFunctionArgs } from '@vue-storefront/core';

export const attachToCartCommand = {
  execute: async (context, params: ComposableFunctionArgs<AttachToCartParams>): Promise<void> => {
    logger.debug('[Magento]: Attach guest cart to user');

    const emailOnCartInput: SetGuestEmailOnCartInput = {
      email: params?.email,
      cart_id: params?.cart?.value?.id,
    };

    await context.app.$vsf.$magento.api.setGuestEmailOnCart(
      {
        ...emailOnCartInput,
      },
      params?.customQuery ?? null,
      params?.customHeaders,
    );
  },
};
