import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import useCart from '~/composables/useCart';
import { useCartStore } from '~/stores';
import type { ShippingCartAddress } from '@vue-storefront/magento-api';
import type {
  UseShippingErrors,
  UseShippingInterface,
  UseShippingLoadParams,
  UseShippingSaveParams,
} from './useShipping';
import _merge from 'lodash.merge';

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */
export function useShipping(): UseShippingInterface {
  const loading = ref(false);
  const error = ref<UseShippingErrors>({ load: null, save: null });
  const { cart, load: loadCart } = useCart();
  const cartStore = useCartStore();
  const { app } = useContext();

  const load = async (params: UseShippingLoadParams = {}): Promise<ShippingCartAddress | null> => {
    logger.debug('useShipping.load');
    let shippingInfo = null;

    try {
      loading.value = true;
      if (cart?.value?.shipping_addresses?.length === 0 || !cart?.value?.id) {
        await loadCart(params);
      }

      shippingInfo = cart.value.shipping_addresses;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      logger.error('useShipping/load', err);
    } finally {
      loading.value = false;
    }
    return shippingInfo;
  };

  const save = async ({ shippingDetails }: UseShippingSaveParams): Promise<ShippingCartAddress | null> => {
    logger.debug('useShipping.save');
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;

      const { id } = cart.value;

      const { apartment, neighborhood, extra, customerAddressId, ...address } = shippingDetails;

      const shippingData = customerAddressId
        ? { customer_address_id: customerAddressId }
        : {
            address: {
              ...address,
              street: [address.street, apartment, neighborhood, extra].filter(Boolean),
            },
          };

      const shippingAddressInput = {
        cart_id: id,
        shipping_addresses: [
          {
            ...shippingData,
          },
        ],
      };

      const { data } = await app.$vsf.$magento.api.setShippingAddressesOnCart(shippingAddressInput);

      logger.debug('[Result]:', { data });

      const shipping_addresses = data?.setShippingAddressesOnCart?.cart?.shipping_addresses;
      if (shipping_addresses) {
        cartStore.cart.shipping_addresses = _merge(cart.value.shipping_addresses, shipping_addresses);
        cartStore.cart.shipping_addresses[0]['id'] = shippingDetails.id;
      }

      shippingInfo = shipping_addresses;

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo;
  };

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useShipping';
export default useShipping;
