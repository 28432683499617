import { defineStore } from 'pinia';
import { PageModel } from '@bloomreach/spa-sdk';
import { reactive, Ref, toRefs, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { useBloomreachPage } from '~/composables';
import logger from '~/utilities/logger';
import { CategoryId, Sku } from '~/composables/useBloomreachPage';

interface ProductUSPState {
  globalUsps: Ref<string[]>;
  skuUsps: Ref<Record<string, any>>;
  categoryUsps: Ref<Record<string, any>>;
  getUspsBySku: (sku: Sku) => Promise<void>;
  getUspsByCategory: (categoryIds: CategoryId[]) => Promise<void>;
}

export const useProductUSPStore = defineStore('productUsps', (): ProductUSPState => {
  const { $brxmEndpointResolver } = useContext();
  const { getBloomreachPage } = useBloomreachPage(useContext() as any);
  const state = reactive({
    globalUsps: <string[]>[],
    skuUsps: <Record<string, string[]>>{},
    categoryUsps: <Record<string, string[]>>{},
  });

  const retrieveGlobalUsps = async () => {
    try {
      const { data } = await axios.get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/pdpusp/global`);
      state.globalUsps = data?.content[data?.document?.$ref.substring(9)].data?.usps;
    } catch (error) {
      logger.warn('Failed to global usps', error);
    }
  };

  if (!state.globalUsps.length) {
    retrieveGlobalUsps();
  }

  const getUspsBySku: ProductUSPState['getUspsBySku'] = async (sku: Sku): Promise<void> => {
    if (!state.skuUsps[sku]?.length) {
      state.skuUsps = {
        ...state.skuUsps,
        [sku]: await getUSP(sku),
      };
    }
  };

  const getUspsByCategory: ProductUSPState['getUspsByCategory'] = async (categoryIds) => {
    state.categoryUsps = {};

    for (const categoryId of categoryIds) {
      if (!state.categoryUsps?.[categoryId]?.length) {
        const categoryUsps = await getUSP(categoryId);
        state.categoryUsps = {
          ...state.categoryUsps,
          [categoryId]: categoryUsps,
        };
      }
    }
  };

  const getUSP = async (searchTerm: CategoryId | Sku): Promise<string[]> => {
    const pageData: PageModel = await getBloomreachPage(searchTerm, 'u');
    if (!pageData?.page) return;

    return (
      Object.values(pageData.page).filter((object) => object?.data?.contentType === 'brxsaas:mjPDPUSP')?.[0]?.data
        ?.usps || []
    );
  };

  return {
    ...toRefs(state),
    getUspsBySku,
    getUspsByCategory,
  };
});

export default useProductUSPStore;
