// eslint-disable-next-line import/no-extraneous-dependencies
import { Category } from '@vue-storefront/magento-api';
import { AgnosticCategoryTree, AgnosticFacet, sharedRef } from '@vue-storefront/core';
import { computed, useRoute, useRouter } from '@nuxtjs/composition-api';
import useUiState from '../../stores/useUiState';
import { storeToRefs } from 'pinia';

const nonFacets = new Set(['p', 'product_list_order', 'q']);

const reduceFilters = (query) => (prev, curr) => {
  const makeArray = Array.isArray(query[curr]) || nonFacets.has(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const useUiHelpers = () => {
  const route = useRoute();
  const router = useRouter();
  const { isMobileMenuOpen } = storeToRefs(useUiState());
  const query = computed(() => route.value.query);

  const offsetY = sharedRef('useUiHelpersOffsetY', 0);

  const getFiltersDataFromUrl = (onlyFilters: boolean = true) =>
    Object.keys(query.value)
      .filter((f) => (onlyFilters ? !nonFacets.has(f) : nonFacets.has(f)))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce(reduceFilters(query.value), {});

  const getFacetsFromUrl = () => ({
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: 28, // Number.parseInt(query.itemsPerPage as string, 10) || 28,
    p: Number.parseInt(query.value.p as string, 10) || 1,
    product_list_order: (query.value.product_list_order as string) || undefined,
    q: query.value.q as string,
  });

  const changeSearchTerm = (term: string) => term;

  const getSearchTermFromUrl = () => ({
    p: Number.parseInt(query.value.p as string, 10) || 1,
    product_list_order: query.value.product_list_order || undefined,
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: 28, // Number.parseInt(query.itemsPerPage as string, 10) || 28,
    q: query.value.q,
  });

  const getCatLink = (category: Category): string => `${category.url_path}${category.url_suffix || ''}`;

  const getAgnosticCatLink = (category: AgnosticCategoryTree): string => `${category.slug}`;

  const changeSorting = async (sort: string) => {
    await router.push({ query: { ...query.value, product_list_order: sort } });
  };

  const changeFilters = async (filters: any, path: string = null) => {
    await router.push({
      path,
      query: {
        ...getFiltersDataFromUrl(false),
        ...filters,
      },
    });
  };

  const changeItemsPerPage = async (itemsPerPage: number) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        itemsPerPage,
      },
    });
  };

  const setTermForUrl = async (term: string) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        q: term || undefined,
      },
    });
  };

  const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';

  const isFacetCheckbox = (): boolean => false;

  const toggleMobileMenuOpenClassOnBody = () => {
    // reason for the spagetti code: https://bugs.webkit.org/show_bug.cgi?id=153852
    if (isMobileMenuOpen.value) {
      offsetY.value = window.scrollY;
      document.body.style.top = `${-offsetY.value}px`;
      document.body.classList.add('is-nav-menu-open');
    } else {
      document.body.classList.remove('is-nav-menu-open');
      document.body.style.removeProperty('top');

      window.scrollTo({
        top: offsetY.value,
        //@ts-ignore
        behavior: 'instant',
      });
    }
  };

  return {
    getFacetsFromUrl,
    getFiltersDataFromUrl,
    getCatLink,
    getAgnosticCatLink,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    setTermForUrl,
    isFacetColor,
    isFacetCheckbox,
    getSearchTermFromUrl,
    changeSearchTerm,
    toggleMobileMenuOpenClassOnBody,
    reduceFilters,
  };
};

export default useUiHelpers;
