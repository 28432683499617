import locale77304fa0 from '../../lang/de.js'
import locale77427e78 from '../../lang/en.js'
import locale77c06943 from '../../lang/nl.js'
import locale77526775 from '../../lang/fr.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"de","numberFormats":{"nl-nl":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"en":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"fr":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"de","file":"de.js","iso":"de","defaultCurrency":"EUR"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"EUR"},{"code":"nl-nl","file":"nl.js","iso":"nl","defaultCurrency":"EUR"},{"code":"fr","file":"fr.js","iso":"fr","defaultCurrency":"EUR"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: false,
  langDir: "/builds/my-jewellery/code/frontend/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "de",
  autoRedirectByLocale: true,
  reloadOnLanguageChange: true,
  autoChangeCookie: {"country":false,"currency":false,"locale":false},
  cookies: {"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale"},
  seo: false,
  normalizedLocales: [{"code":"de","file":"de.js","iso":"de","defaultCurrency":"EUR"},{"code":"en","file":"en.js","iso":"en","defaultCurrency":"EUR"},{"code":"nl-nl","file":"nl.js","iso":"nl","defaultCurrency":"EUR"},{"code":"fr","file":"fr.js","iso":"fr","defaultCurrency":"EUR"}],
  localeCodes: ["de","en","nl-nl","fr"],
  additionalMessages: [],
}

export const localeMessages = {
  'de.js': () => Promise.resolve(locale77304fa0),
  'en.js': () => Promise.resolve(locale77427e78),
  'nl.js': () => Promise.resolve(locale77c06943),
  'fr.js': () => Promise.resolve(locale77526775),
}
