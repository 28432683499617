import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import { useCartStore, useCustomerStore, wishlistStore } from '~/stores';
import { parseCookieString } from '~/helpers/stringHelpers';
import logger from '~/utilities/logger';
const cookieNames = require('~/enums/cookieNameEnum');

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) =>
  res?.errors?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin: Plugin = ({ app }) => {
  const cartStore = useCartStore();
  const customerStore = useCustomerStore();
  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    customerStore.setIsLoggedIn(true);
  }

  const fetchNewCustomerToken = async (phpSessionId: string) => {
    try {
      const { data } = await app.$axios({
        method: 'POST',
        url: `/magento/renewCustomerToken`,
        baseURL: app.$config.middlewareUrl,
        withCredentials: true,
        headers: {
          'x-php-session-id': phpSessionId,
        },
      });
      return data?.customerToken;
    } catch (error) {
      return null;
    }
  };

  app.$vsf.$magento.client.interceptors.response.use(
    async (res: { request: any; config: any; data: ApolloQueryResult<unknown> }) => {
      if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
        return res;
      }

      const phpSessionId = process.server
        ? app.$cookies.get(cookieNames.phpSessionIdCookieName)
        : parseCookieString(document.cookie)[cookieNames.phpSessionIdCookieName];
      const customerToken = await fetchNewCustomerToken(phpSessionId);

      if (customerToken) {
        logger.debug('renewCustomerToken succeeded:', customerToken);
        app.$cookies.set(cookieNames.customerCookieName, customerToken, {
          path: '/',
          sameSite: 'lax',
          maxAge: 60 * 60 * 24 * 365,
        });

        return app.$vsf.$magento.client({
          ...res.config,
          headers: {
            ...res.config.headers,
            Authentication: `Bearer ${customerToken}`,
          },
        });
      }

      cartStore.clearCart();
      customerStore.setIsLoggedIn(false);

      const { clear } = wishlistStore();
      clear();

      return false;
    },
  );
};

export default plugin;
