import type { PlaceOrderOutput } from '@vue-storefront/magento-api';
import { ComposableFunctionArgs } from '@vue-storefront/core';

import { removeItem } from '~/helpers/asyncLocalStorage';
import { useCartStore } from '~/stores';

export const placeOrderCommand = {
  execute: async (context, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    const cartStore = useCartStore();

    const { data } = await context.app.$vsf.$magento.api.placeOrder({ cart_id: cartId }, params?.customQuery ?? null);

    // Reset cart
    cartStore.clearCart();
    context.app.$vsf.$magento.config.state.setCartId();

    await removeItem('checkout');
    await context.app.router.push(
      `${context.app.localePath(`/checkout/thank-you?order=${data?.placeOrder.order.order_number}`)}`,
    );
    return data?.placeOrder ?? null;
  },
};
