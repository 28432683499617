import { computed, ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

interface Redirect {
  uri: string;
  statusCode: number;
}

interface Route {
  hostType: 'VSF' | 'MAGENTO' | 'DEFAULT';
  id: string | null;
  pageType: 'CMS_PAGE' | 'DEFAULT' | 'UNKOWN'; // typo
  redirect: Redirect | null;
}

export interface RouterData {
  uri: string;
  route: Route;
}

/**
 * Nginx router API
 */
export const useRouterApi = defineStore('routerApi', () => {
  const routerData = ref<RouterData[]>([]);
  const baseUrl = ref(process.env.NUXT_ENV_STORE_URL || '');
  // Running as Middleware for '(https://nginxrouter.myjewellery.sqli-gcp.nl)/router-api'
  // const apiUrl = computed(() => `${process.server ? baseUrl.value : ''}/api/nginxRouterApi`);
  const apiUrl = process.env.NUXT_ENV_ROUTER_API_ENDPOINT || '/router-api';

  const setBaseUrl = (uri) => (baseUrl.value = uri);
  const getDataByUri = (uri) => routerData.value.find((data) => data?.uri === uri);
  const addData = (data: RouterData) => routerData.value.push(data);

  return { routerData, apiUrl, addData, getDataByUri, setBaseUrl };
});

export default useRouterApi;
