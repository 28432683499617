import Vue from 'vue';
import Router from 'vue-router';
import { usePageStore } from '~/stores';

import Page from '~/pages/Page.vue';
import Catalog from '~/pages/Catalog.vue';
import Search from '~/pages/Search.vue';
import Login from '~/pages/Login.vue';
import MyAccount from '~/pages/MyAccount.vue';
import Checkout from '~/pages/Checkout.vue';
import Cart from '~/pages/Checkout/Cart.vue';
import UserAccount from '~/pages/Checkout/UserAccount.vue';
import Shipping from '~/pages/Checkout/Shipping.vue';
import Payment from '~/pages/Checkout/Payment.vue';
import Overview from '~/pages/Checkout/Overview.vue';
import ThankYou from '~/pages/Checkout/ThankYou.vue';
import ResetPassword from '~/pages/ResetPassword.vue';
import ExternalCheckoutThankYou from '~/pages/Checkout/ExternalCheckoutThankYou.vue';

Vue.use(Router);

const localesString = 'de=de,en=en,nl-nl=nl,fr=fr';
const locales = localesString.split(',').map((l) => {
  var lc = l.split('=');
  var code = lc[0];
  var iso = lc[1];
  const localeConfig = {
    code: code,
    file: iso + '.js',
    iso: iso,
    defaultCurrency: 'EUR',
  };
  return localeConfig;
});

/**
 * Generate a localized route for a generic, non-localized route.
 * Handles route children recursively.
 */
const createLocalizedRoute = (route, locale) => {
  let routeName = `${route.name}___${locale.code}`;

  const localizedRoute = {
    ...route,
    path: `/${locale.code}${route.path}`,
    name: routeName,
  };
  if (route.children && route.children.length) {
    localizedRoute.children = route.children.map((childRoute) =>
      createLocalizedRoute({ ...childRoute, path: `${route.path}/${childRoute.path}` }, locale),
    );
  }
  return localizedRoute;
};

/**
 * Generates all localized routes.
 */
const createLocalizedRoutes = (routes, locales) => {
  return routes.reduce((i18nRoutes, route) => {
    locales.forEach((locale) => {
      i18nRoutes.push(createLocalizedRoute(route, locale));
    });
    return i18nRoutes;
  }, []);
};

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: createLocalizedRoutes(
      [
        {
          name: 'catalog',
          path: '/(.*).html',
          component: Catalog,
          meta: {
            type: 'CATALOG',
          },
        },
        {
          name: 'search',
          path: '/catalogsearch/result/',
          component: Search,
          meta: {
            type: 'SEARCH',
          },
        },
        {
          name: 'login',
          path: '/customer/account/login',
          alias: ['/customer/account/login/'],
          component: Login,
          props: { pageName: 'login' },
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'create-account',
          path: '/customer/account/create/',
          alias: ['/customer/account/create'],
          component: Login,
          props: { pageName: 'register' },
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'logout-success',
          path: '/customer/account/logoutSuccess',
          component: Login,
          props: { pageName: 'logoutSuccess' },
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'forgot-password',
          path: '/customer/account/forgotpassword',
          component: Login,
          props: { pageName: 'forgotpassword' },
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'my-account',
          path: '/customer/account/:pageName?',
          component: MyAccount,
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'wishlist',
          path: '/wishlist',
          component: MyAccount,
          params: {
            pageName: 'wishlist',
          },
          meta: {
            type: 'WISHLIST',
          },
        },
        {
          name: 'checkout',
          path: '/checkout',
          component: Checkout,
          meta: {
            type: 'CHECKOUT',
          },
          children: [
            {
              path: 'cart',
              name: 'cart',
              component: Cart,
            },
            {
              path: 'user-account',
              name: 'user-account',
              component: UserAccount,
            },
            {
              path: 'shipping',
              name: 'shipping',
              component: Shipping,
            },
            {
              path: 'overview',
              name: 'overview',
              component: Overview,
            },
            {
              path: 'payment',
              name: 'payment',
              component: Payment,
            },
            {
              path: 'thank-you',
              name: 'thank-you',
              component: ThankYou,
            },
            {
              path: 'external-thank-you',
              name: 'external-thank-you',
              component: ExternalCheckoutThankYou,
            },
          ],
        },
        {
          name: 'reset-password',
          path: '/reset-password',
          alias: ['/customer/account/createPassword'],
          component: ResetPassword,
          meta: {
            type: 'ACCOUNT',
          },
        },
        {
          name: 'page',
          path: '/:slugs(.*)*',
          component: Page,
          meta: {
            type: 'PAGE',
          },
        },
      ],
      locales,
    ),
    scrollBehavior(to, from, savedPosition) {
      const pageStore = usePageStore();
      pageStore.loaded = false;

      if (pageStore.previousPage) {
        pageStore.previousPage.path = from.path;
      }

      if (to.meta.type !== 'CATALOG') {
        pageStore.routeData.type = to.meta.type;
      }

      if (savedPosition) {
        const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'));
        const scrollPosition = scrollPositions[to.fullPath];

        if (!['CATEGORY', 'SEARCH'].includes(pageStore.routeData?.type)) {
          return { x: 0, y: scrollPosition, behavior: 'instant' };
        }

        // Wait until categoryPage is fully loaded before scrolling
        return new Promise((resolve) => {
          let timePassed = 0;
          const interval = setInterval(() => {
            timePassed += 100;
            if (pageStore.loaded || timePassed > 2500) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        }).then(() => {
          // return for some reason doesnt work when you are on p=2 and open a product from p=1
          window.scrollTo({
            behavior: 'instant',
            top:
              document.querySelector(`[data-sku=${scrollPosition.sku?.replace(' ', '_')}]`).getBoundingClientRect()
                .top -
              document.body.getBoundingClientRect().top -
              scrollPosition.offsetTop,
          });
        });
      } else if (to.hash) {
        const position = {};
        position.selector = to.hash;
        if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
          return position;
        }
        return false;
      } else if (Object.keys(to.query).length !== 0 && ['CATALOG', 'SEARCH'].includes(to.meta.type)) {
        pageStore.loaded = true;
        return false;
      } else {
        return { x: 0, y: 0, behavior: 'instant' };
      }
    },
  });
}
