import { useContext, useRoute } from '@nuxtjs/composition-api';
import { Route } from 'vue-router';
import _debounce from '~/helpers/debounce';
import {
  useCartStore,
  useCustomerStore,
  usePageStore,
  useBloomreachDiscoveryStore,
  useProductStore,
  useBreadcrumbsStore,
} from '~/stores';
import { Product } from '~/types/interfaces/product';

enum ListType {
  'catalog_product_view' = 'detail',
  'catalog_category_view' = 'category',
  'cms_index_index' = 'other',
  'cms_page_view' = 'other',
}

interface CartItem {
  product: Product;
  sku: string;
  variant: string;
  quantity: number;
}

export const useGTM = () => {
  const {
    app: { $gtm, i18n, router },
    $config,
  } = useContext();
  const route = useRoute();
  const cartStore = useCartStore();
  const customerStore = useCustomerStore();
  const pageStore = usePageStore();
  const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
  const productStore = useProductStore();
  const breadcrumbsStore = useBreadcrumbsStore();

  const trackCustomerSession = _debounce(() => {
    const mapCartItems = (items) => {
      return items?.map(
        (item) =>
          (item
            ? {
                sku: item.configured_variant?.sku || item.product?.sku, // 'MJ07235-0100-S',
                parent_sku: item.product?.sku, // 'MJ07235-0100',
                name: item.product?.name, //  'Bruine rib top met col',
                product_type: item.product?.__typename?.replace('Product', '').toLowerCase() || 'simple', // 'configurable' | 'simple' | ...
                price: Math.round((item.prices?.row_total_including_tax.value / 1.21) * 100) / 100, // 21.48,
                price_incl_tax: item.prices?.row_total_including_tax.value, // 25.99,
                discount_amount: item.prices?.total_item_discount.value,
                tax_amount: Math.round((item.prices?.row_total_including_tax.value / 1.21) * 0.21 * 100) / 100,
                quantity: item.quantity,
                variant: item.configurable_options?.map((option) => option.value_label).join(' / ') || '',
                categories: item.product?.categories?.map((cat) => cat?.name) || [],
                category: item.product?.categories?.[0]?.name || '',
                all_categories:
                  Object.fromEntries(
                    item.product?.categories?.map((cat) => [Buffer.from(cat?.uid, 'base64').toString(), cat?.name]) ||
                      [],
                  ) || [],
                p_id: parseInt(Buffer.from(item.product?.uid, 'base64').toString()) || 0,
              }
            : {}) || [],
      );
    };

    const customer = {
      isLoggedIn: customerStore.isLoggedIn,
      id: undefined,
      groupId: customerStore.isLoggedIn ? '1' : undefined,
      email: customerStore.user?.email || undefined,
    };

    Object.keys(customer).forEach((key) => customer[key] === undefined && delete customer[key]);

    const session = {
      event: 'mpCustomerSession',
      customer: {
        ...customer,
      },
      cart: {
        hasItems: Boolean(cartStore.cart?.items),
        items: mapCartItems(cartStore.cart?.items),
        total: cartStore.cart?.prices?.grand_total?.value || 0,
        itemCount: cartStore.cart?.items?.length || 0,
        cartQty: cartStore.cart?.total_quantity || 0,
        hasCoupons: Boolean(cartStore.cart?.applied_coupons),
      },
    };

    $gtm.push(session);
  }, 500);

  const handleCategory = async (page: any, to: Route) => {
    page.pageType = 'catalog_category_view';
    page.list = 'category';

    // Wait until bloomreachDiscovery and page are loaded
    // skip if loading takes longer than 5 seconds
    await new Promise<void>((resolve) => {
      const timeout = setTimeout(resolve, 5000);
      const checkLoaded = () => {
        if (!bloomreachDiscoveryStore.loading && pageStore.loaded) {
          clearTimeout(timeout);
          resolve();
        } else {
          requestAnimationFrame(checkLoaded);
        }
      };
      checkLoaded();
    });

    const category = {
      event: 'categoryPage',
      category: {
        id: pageStore.routeData?.id,
        name: pageStore.routeData?.name,
        path: pageStore.routeData?.relative_url.replaceAll('.html', '').replaceAll('/', ' > '),
        productCount: bloomreachDiscoveryStore.searchResponse?.response?.numFound || 0,
        productsPageLoaded: bloomreachDiscoveryStore.searchResponse?.response?.docs?.length,
        page: to.query.p || 1,
      },
    };
    $gtm.push(category);
  };

  const handleProduct = async (page: any, to: Route) => {
    page.pageType = 'catalog_product_view';
    page.list = 'detail';

    const sku = pageStore?.routeData?.sku;
    await productStore.waitForProduct(sku);
    const product = productStore.products[sku];
    if (!product) return;

    const productPage = {
      event: 'productPage',
      product: {
        all_categories: product.categories?.reduce((acc, curr) => ((acc[curr.id] = curr.name), acc), {}),
        attribute_set_id: null,
        category: breadcrumbsStore.breadcrumbs
          ? Array.from(breadcrumbsStore.breadcrumbs).pop()?.[0]
          : product.categories?.[0],
        id: product.pid,
        image_url: product.image?.url,
        name: product.name,
        p_id: product.pid,
        parent_sku: null,
        path: pageStore.routeData?.relative_url.replaceAll('.html', '').replaceAll('/', ' > '),
        price: product.price_range?.minimum_price?.final_price?.value,
        product_type: product.__typename?.replace('Product', '').toLowerCase() || 'simple',
        sku,
        variants: product.variants?.map((variant, index) => ({
          id: variant?.product.sku,
          p_id: variant?.product.id,
          position: index,
          stock_status: variant?.product.stock_status,
          name: variant?.attributes?.[0]?.label,
        })),
        lookup: {
          [sku]: product?.pid,
          ...product?.variants?.reduce((acc, curr) => ((acc[curr.product.sku] = curr.product.id), acc), {}),
        },
      },
    };

    const productDetail = {
      event: 'productDetail',
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              id: product?.sku,
              p_id: product?.pid,
              name: product?.name,
              position: 0,
              product_type: product?.__typename?.replace('Product', '').toLowerCase() || 'simple',
            },
            ...product?.variants?.map((variant, index) => ({
              id: variant?.product.sku,
              p_id: variant?.product.id,
              name: variant?.attributes?.[0]?.label,
              position: index + 1,
              product_type: variant.product?.__typename?.replace('Product', '').toLowerCase() || 'simple',
            })),
          ],
        },
      },
    };
    $gtm.push(productPage);
    $gtm.push(productDetail);
  };

  const trackPageEvent = async (to: Route) => {
    const fullPath = route.value.fullPath.replace('/' + i18n.locale, '');
    const pageType: string = fullPath === '/' ? 'cms_index_index' : 'cms_page_view';
    const list = ListType[pageType];

    const page = {
      ecommerce: {
        currencyCode: 'EUR',
      },
      pageType: pageStore.routeData?.type || pageType,
      pageUrl: to.fullPath,
      list,
    };

    switch (pageStore.routeData?.type) {
      case 'CATEGORY':
        handleCategory(page, to);
        break;
      case 'PRODUCT':
        handleProduct(page, to);
        break;
      default:
        // set tracking for regular pages
        break;
    }

    $gtm.push(page);
  };

  const trackCartChange = (event: string, cartItem: CartItem) => {
    const p = cartItem.product;
    const product = {
      name: p.name,
      id: cartItem.sku,
      price: p?.price_range?.maximum_price?.final_price?.value,
      quantity: cartItem.quantity,
      parent_sku: p.sku,
      variant: cartItem.variant,
      category: p.categories?.[0]?.name,
      all_categories: p.categories?.map((category) => category.name),
      p_id: p.pid,
    };

    const payload = {
      event,
      ecommerce: {
        currencyCode: 'EUR',
      },
      cart: {},
    };

    payload.ecommerce[event === 'addToCart' ? 'add' : 'remove'] = { products: [product] };
    payload.cart[event === 'addToCart' ? 'add' : 'remove'] = { products: [product] };
    $gtm.push(payload);
  };

  const init = () => {
    // Skip GTM when within Experience Manager.
    if (route.value?.query?.token) {
      return;
    }

    $gtm.init($config.gtmId);

    router.afterEach((to: Route, from: Route) => {
      setTimeout(() => {
        trackPageEvent(to);
      }, 250);
    });

    setTimeout(() => {
      trackPageEvent(route.value);
    }, 250);
    trackCustomerSession();

    cartStore.$subscribe((mutation, state) => {
      trackCustomerSession();
    });

    customerStore.$subscribe((mutation, state) => {
      trackCustomerSession();
    });
  };

  return {
    init,
    trackCartChange,
  };
};

export default useGTM;
